import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    Text,
} from "@chakra-ui/react";
import {ErrorMessage} from "@hookform/error-message";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {ModalRecoverPassword} from "../../components/ModalRecoverPassword";

import {userAuthentication} from "../../services/LoginService";

export function Login() {
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({criteriaMode: "all"});
    const navigate = useNavigate();
    const [isLoggingIn, setIsLogginIn] = useState(false);
    const loginUser = (user) => {
        setIsLogginIn(true);
        userAuthentication(user)
            .then((response) => {
                setIsLogginIn(false);
                localStorage.clear();
                localStorage.setItem("token", response.data.accessToken);
                localStorage.setItem("idUser", response.data.idUser);
                navigate("/calendar");
            })
            .catch((error) => {
                toast.error(error.response.data);
                setIsLogginIn(false);
            });
    };
    const companyName = process.env.REACT_APP_COMPANY_NAME;
    const secondaryLogo = require(`../../assets/${companyName}/imgs/secondary-logo.png`);
    const mobileLogo = require(`../../assets/${companyName}/imgs/mobile-logo.png`);
    const principalLogo = require(`../../assets/${companyName}/imgs/principal-logo.png`);
    const cssFilePath = require(`../../assets/${companyName}/css/style.css`);

    return (
        <>
            <div className="login-background">
                <Flex
                    className="container-fullscreen"
                >
                    <Flex
                        className="modal-logo"
                    >
                        <Image
                            className="imgLogo"
                            src={secondaryLogo}
                            alt="simbiose"
                            boxSize="100px, 50px"
                        />

                        <Image
                            src={principalLogo}
                            alt="simbiose"
                            boxSize="100px, 50px"
                        />
                    </Flex>
                    <Flex
                        className="modal-login"
                    >

                        <Image
                            className="imgMobile"
                            display="none"
                            src={mobileLogo}
                            alt="simbiose"
                            boxSize="100px"
                        />

                        <Flex
                            flexDir="column"
                            mb={35}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Heading
                                className="text-welcome"
                            >
                                Bem-vindo ao Simbioff
                            </Heading>
                            <Text
                                className="text-login"
                                fontSize="18px"
                            >
                                {companyName === "simbiose" ?
                                "Um site dedicado ao colaborador Simbiose." :
                                "Um site dedicado ao colaborador MNGT construído pela Simbiose."}
                            </Text>
                        </Flex>
                        <Flex flexDirection="column"
                              alignItems="center"
                              width="415px"
                        >
                            <form id="loginForm" onSubmit={handleSubmit(loginUser)}>
                                <FormLabel className="input-label">Email:</FormLabel>
                                <Input
                                    id="inputLogin"
                                    className="input"
                                    backgroundColor="#FFFFFF"
                                    focusBorderColor="#637D87"
                                    placeholder="Digite seu e-email"
                                    _placeholder={{
                                        color: "#637D87",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                    }}
                                    {...register("email", {
                                        required: 'Campo "Email" obrigatório!',
                                        pattern: {
                                            value:
                                                /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(simbioseventures|grupomngt|larincrivel|energiaamiga|maisarmazem|areaincrivel)\.com(\.br)?$/g,
                                            message: "E-mail inválido",
                                        },
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="email"
                                    render={({messages}) =>
                                        messages &&
                                        Object.entries(messages).map(([type, message]) => (
                                            <Text className="error-message" key={type}>
                                                {message}
                                            </Text>
                                        ))
                                    }
                                />

                                <FormControl mt={5}>
                                    <FormLabel className="input-label">Senha:</FormLabel>
                                    <Input
                                        id="inputLogin"
                                        className="input"
                                        backgroundColor="#FFFFFF"
                                        focusBorderColor="#637D87"
                                        type="password"
                                        placeholder="Digite sua senha"
                                        _placeholder={{
                                            color: "#637D87",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                        }}
                                        {...register("password", {
                                            required: 'Campo "Senha" obrigatório!',
                                        })}
                                    />
                                </FormControl>
                                <ErrorMessage
                                    errors={errors}
                                    name="password"
                                    render={({messages}) =>
                                        messages &&
                                        Object.entries(messages).map(([type, message]) => (
                                            <Text className="error-message" key={type}>
                                                {message}
                                            </Text>
                                        ))
                                    }
                                />
                            </form>
                            <Flex>
                                <ModalRecoverPassword/>
                            </Flex>

                            <Button
                                className="login-button"
                                variant="solid"
                                _active={{}}
                                _hover={{}}
                                type="submit"
                                isLoading={isLoggingIn}
                                form="loginForm"
                            >
                                Entrar
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </div>

        </>
    );
}
