import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Skeleton,
  SkeletonCircle,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  useDisclosure,
  css,
} from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/textarea";
import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { EVENTS_URL } from "../endpoints/endpoints";

import AuxiliaryService from "../services/AuxiliaryService";
import { api } from "../services/AxiosService";
import { getAtestadoByDayoffId } from "../services/DayoffService";
import EmployeesService from "../services/EmployeesService";
import { getDayOffByUserId } from "../services/GetDayOffsByUser";
import UpDateDayOff from "../services/UpDateDayOff";
import { DayOffList } from "./DayOffList";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { NewPassword } from "./NewPassword";
import { UploadProfilePicture } from "./UploadProfilePicutreModel";
import DayOffTabs from "./DayOffTabs";

export function Profile() {
  const companyName = process.env.REACT_APP_COMPANY_NAME;
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [dayoffStatus, setDayoffStatus] = useState("");
  const [role, setRole] = useState(null);
  const [index, setIndex] = useState(null);
  const [dayOffs, setDayOffs] = useState([]);
  const [imgLink, setImgLink] = useState("");
  const [defaultIndex, setDefaultIndex] = useState(0);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [dayoff, setDayoff] = useState([]);
  const [atestadoLink, setAtestadoLink] = useState("");
  const [showDocument, setShowDocument] = useState(false);
  const [loadingUpdateDayoffButton, setLoadingUpdateDayoffButton] =
    useState(false);
  const [eventId, setEventId] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ criteriaMode: "all" });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    watch: watch1,
    formState: { errors1 },
  } = useForm({ criteriaMode: "all" });

  const depPed = watch("ped");
  const depEnglish = watch("english");
  const depTherapy = watch("therapy");
  const depUndergraduate = watch("undergraduate");
  const depGraduate = watch("graduate");
  const depHasChildren = watch("hasChildren");

  async function getUserIdFromUrl() {
    let url = window.location.href.substring();

    if (!url.includes("id")) {
      return;
    }
    const eventId = url.substring(url.lastIndexOf("=") + 1, url.length);
    if (eventId > 0) {
      setDefaultIndex(4);
    }
    setEventId(eventId);
    getAtestadoLink(eventId);
    await fetchDayoffById(eventId);
  }

  function fetchDayoffById(eventId) {
    api
      .get(EVENTS_URL + eventId)
      .then((res) => {
        setDayoff(res.data);
        onOpen();
      })
      .catch();
  }

  function fetchEventsByUserId() {
    const idUser = localStorage.getItem("idUser");
    getDayOffByUserId(idUser).then((res) => {
      setDayOffs(res.data);
    });
  }

  function fetchUserById() {
    const idUser = localStorage.getItem("idUser");
    EmployeesService.getEmployeeById(idUser).then((res) => {
      setUser(res.data);
      setIsLoading(false);
    });
  }

  function getAtestadoLink(eventId) {
    getAtestadoByDayoffId(eventId)
      .then((res) => {
        setAtestadoLink(res.data);
      })
      .catch()
      .finally();
  }

  function fetchUserPicture() {
    const exists = localStorage.getItem("userProfilePicture");
    if (exists) {
      setImgLink(exists);
      return;
    }

    EmployeesService.getProfilePicture().then((response) => {
      setImgLink(response.data);
    });
  }
  useEffect(() => {
    fetchUserById();
    fetchUserPicture();
    fetchEventsByUserId();
    getUserIdFromUrl();
  }, []);

  useEffect(() => {
    const idUser = localStorage.getItem("idUser");
    EmployeesService.dayoffStatus(idUser).then((res) => {
      setDayoffStatus(res.data);
    });
  }, []);

  useEffect(() => {
    const idUser = localStorage.getItem("idUser");
    EmployeesService.getEmployeeById(idUser).then((res) => {
      setRole(res.data.roles[0].role);
    });
  }, []);

  function verifyCep(zip) {
    if (zip.length < 9) return;
    AuxiliaryService.checkCep(zip).then((res) => {
      setValue("country", "Brasil");
      setValue("state", res.data.uf);
      setValue("city", res.data.localidade);
      setValue("district", res.data.bairro);
      setValue("street", res.data.logradouro);
    });
  }

  function openNewWindow(url) {
    window.open(url, "_blank", "noreferrer");
  }

  const editar = (employee) => {
    const idUser = localStorage.getItem("idUser");
    setIsButtonLoading(true);
    if (depPed === "false") {
      employee.responsiblePed = null;
    }
    if (depEnglish === "false") {
      employee.englishTeacher = null;
    }
    if (depTherapy === "false") {
      employee.responsibleTherapist = null;
    }
    if (depUndergraduate === "false") {
      employee.undergraduateCourseName = null;
    }
    if (depGraduate === "false") {
      employee.graduateCourseName = null;
    }
    if (depHasChildren === "false") {
      employee.childrenQty = null;
    }
    EmployeesService.updateEmployee(idUser, employee)
      .then((res) => {
        toast.success(res.data);
        fetchUserById();
      })
      .catch((error) => {
        toast.error(error.response.data);
      })
      .finally(() => {
        setIsButtonLoading(false);
      });
  };

  const updateDayoff = (dayoff) => {
    setLoadingUpdateDayoffButton(true);
    dayoff.status = "PENDING";
    const americanDate = AuxiliaryService.formatarDataPadraoAmericano(
      dayoff.date
    );
    
    const arquivo = dayoff.file === undefined ? undefined : dayoff.file[0]
   
    
    dayoff.date = americanDate;
    // const arquivo = dayoff.file === undefined ? null : dayoff.file[0];
    const formData = new FormData();
    formData.append(
      "updateEventsDto",
      new Blob([JSON.stringify(dayoff)], {
        type: "application/json",
      })
    );
    if (!arquivo) {
      formData.append("arquivo", new Blob(["teste"], { type: "text-plain" }));
    } else {
      formData.append("arquivo", arquivo);
    }
    
    UpDateDayOff(eventId, formData)
      .then((res) => {
        toast.success(res.data);
        onClose()
      })
      .catch((err) => {
        toast.error(err.response.data);
      })
      .finally(() => {
        setLoadingUpdateDayoffButton(false);
      });
  };

  if (isLoading) {
    return (
      <>
        <div className="description-title-b">
          <div className="description-title">
            <Flex mb={4}>
              <SkeletonCircle size="70" />
              <Box ml="3">
                <Skeleton>
                  <Heading size="lg" color="gray.600" mb={4}>
                    Nome completo do usuário aqui
                  </Heading>
                </Skeleton>

                <Skeleton height="10px">
                  <Text fontSize="md" color="gray.600">
                    Colaborador desde dd/mm/aaaa
                  </Text>
                </Skeleton>
              </Box>
            </Flex>

            <HStack spacing={6}
                    overflowX="auto"
                    css={css({
                      scrollbarWidth: 'none',
                      '::-webkit-scrollbar': {display: 'none'},
                      '-webkit-overflow-scrolling': 'touch',
                    })}
            >
              <Skeleton>
                <ButtonGroup
                  size="sm"
                  isAttached
                  variant="solid"
                  colorScheme="blue"
                >
                  <Button>Dayoffs disponíveis</Button>
                  <Button>{dayoffStatus.daysOffRemmaing}</Button>
                </ButtonGroup>
              </Skeleton>

              <Skeleton>
                <ButtonGroup
                  size="sm"
                  isAttached
                  variant="outline"
                  colorScheme="blue"
                >
                  <Button>Dayoffs tirados</Button>
                  <Button>{dayoffStatus.dayOffRequestds}</Button>
                </ButtonGroup>
              </Skeleton>

              <Skeleton>
                <ButtonGroup
                  size="sm"
                  isAttached
                  variant="outline"
                  colorScheme="blue"
                >
                  <Button>Dias totais trabalhados</Button>
                  <Button>{dayoffStatus.workingDays}</Button>
                </ButtonGroup>
              </Skeleton>
            </HStack>
          </div>
        </div>

        <div className="description-box">
          <SimpleGrid
            columns={["1", "1", "2", "3"]}
            spacingY="20px"
            spacingX="20px"
            max-width="1000px"
            margin="auto"
            padding="10px"
            id="simple-grid-skeleton"
          >

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

            <FormControl>
              <Skeleton width="60px" height="10px" mb={4}>
                <FormLabel>Input:</FormLabel>
              </Skeleton>
              <Skeleton>
                <Input type="text" />
              </Skeleton>
            </FormControl>

          </SimpleGrid>
        </div>
      </>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Visualizando dayoff</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit1(updateDayoff)} id="update-dayoff">
              <FormControl>
                <FormLabel className="input-label">Tipo do Dayoff:</FormLabel>
                <Select
                  className="input-profile"
                  value={dayoff.typeDay}
                  {...register1("typeDay")}
                >
                  <option value="DAYOFF">Dayoff</option>
                  <option value="HALF_DAYOFF">Meio Dayoff</option>
                </Select>
              </FormControl>
              {dayoff.typeDay === "HALF_DAYOFF" ? (
                <>
                  <FormControl>
                    <FormLabel className="input-label">Início:</FormLabel>
                    <Input
                      type="time"
                      min="07:00:00"
                      max="16:00:00"
                      step="1"
                      defaultValue={dayoff.startTime}
                      {...register1("startTime")}
                    />
                  </FormControl>
                </>
              ) : null}

              <FormControl>
                <FormLabel className="input-label">Data Início:</FormLabel>
                <Input
                  as={InputMask}
                  mask="99/99/9999"
                  maskChar={null}
                  defaultValue={AuxiliaryService.formatarDataParaLocal(
                    dayoff.date
                  )}
                  {...register1("date")}
                />
              </FormControl>

              <FormControl>
                <FormLabel className="input-label">Justificativa:</FormLabel>
                <Textarea
                  {...register1("justification")}
                  defaultValue={dayoff.justification}
                />
              </FormControl>

              {dayoff.hasAtestado ? (
                <>
                  <Alert status="warning" mt={5}>
                    <Flex flexDir="column">
                      <Flex>
                        <AlertIcon />

                        <AlertDescription>
                          Este dayoff possui documento anexado, clique no botão
                          abaixo para visualizar.
                        </AlertDescription>
                      </Flex>
                      <Flex justifyContent="center">
                        <Button
                          className="button"
                          mt={2}
                          rightIcon={<ExternalLinkIcon />}
                          onClick={() => openNewWindow(atestadoLink)}
                        >
                          Visualizar documento
                        </Button>
                      </Flex>
                    </Flex>
                  </Alert>
                </>
              ) : null}

              <FormControl mt={3} mb={3}>
                <Flex alignItems="center">
                  <FormLabel className="input-label">
                    {dayoff.hasAtestado
                      ? "Deseja reenviar o atestado?"
                      : "Deseja enviar atestado?"}
                  </FormLabel>
                  <Switch
                    onChange={() => {
                      showDocument === false
                        ? setShowDocument(true)
                        : setShowDocument(false);
                    }}
                  />
                </Flex>
              </FormControl>

              {showDocument ? (
                <FormControl>
                  <Input type="file" {...register1("file")} />
                </FormControl>
              ) : null}
            </form>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" form="update-dayoff" isLoading={loadingUpdateDayoffButton} className="button">
              Atualizar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Header />

      <div className="description-title-b">
        <div className="description-title">
          <Flex mb={4}>
            <Avatar size="lg" name={user.fullName} src={imgLink}>
              <UploadProfilePicture />
            </Avatar>
            <Box ml="3">
              <Heading size="lg" className="text-color">
                {user.fullName}
              </Heading>

              <Text fontSize="sm" className="text-profile" as="b">
                {role === "ROLE_ADMIN" && (
                  <Tag colorScheme="blue" mr={3} size="sm" as="b">
                    <Text as="b">Admin</Text>
                  </Tag>
                )}
                Colaborador desde{" "}
                {AuxiliaryService.formatarDataParaLocal(user.startOnTeam)}
              </Text>
            </Box>
          </Flex>

          {companyName === "grupomngt" && (
            <HStack spacing={6}
                    overflowX="auto"
                    css={css({
                      scrollbarWidth: 'none',
                      '::-webkit-scrollbar': {display: 'none'},
                      '-webkit-overflow-scrolling': 'touch',
                    })}
            >
              <ButtonGroup
                size="sm"
                isAttached
                variant="solid"
                colorScheme="blue"
              >
                <Button>Dayoffs disponíveis</Button>
                <Button>{dayoffStatus.daysOffRemmaing}</Button>
              </ButtonGroup>

              <ButtonGroup
                size="sm"
                isAttached
                variant="outline"
                colorScheme="blue"
              >
                <Button>Dayoffs tirados</Button>
                <Button>{dayoffStatus.dayOffRequestds}</Button>
              </ButtonGroup>

              <ButtonGroup
                size="sm"
                isAttached
                variant="outline"
                colorScheme="blue"
              >
                <Button>Dias totais trabalhados</Button>
                <Button>{dayoffStatus.workingDays}</Button>
              </ButtonGroup>
            </HStack>)}

          {companyName === "simbiose" && (
              <DayOffTabs dayoffStatus={dayoffStatus} />
          )}

        </div>
      </div>

      <div className="description-box">
        <Tabs
          size="sm"
          onChange={(index) => setIndex(index)}
          defaultIndex={defaultIndex}
        >
          <TabList
              overflowX="auto"
              overflowY="hidden"
              css={css({
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {display: 'none'},
                '-webkit-overflow-scrolling': 'touch',
              })}
          >
            <Tab _selected={{borderBottom: "4px solid #284D74", color: "#284D74", fontWeight: "600"}}>
              Informações básicas
            </Tab>
            <Tab _selected={{borderBottom: "4px solid #284D74", color: "#284D74", fontWeight: "600"}}>
              Outras informações
            </Tab>
            <Tab _selected={{borderBottom: "4px solid #284D74", color: "#284D74", fontWeight: "600"}}>
              Informações pessoais
            </Tab>
            <Tab _selected={{borderBottom: "4px solid #284D74", color: "#284D74", fontWeight: "600"}}>
              Alterar senha
            </Tab>
            <Tab _selected={{borderBottom: "4px solid #284D74", color: "#284D74", fontWeight: "600"}}>
              Meus dayoffs
            </Tab>
          </TabList>
          <TabPanels pt={5} pb={5}>
            <TabPanel>
              <form id="profileForm" onSubmit={handleSubmit(editar)}>
                <SimpleGrid
                  columns={["1", "1", "2", "3"]}
                  spacingY="20px"
                  spacingX="20px"
                  max-width="1000px"
                  margin="auto"
                  padding="0px"
                >
                  <FormControl>
                    <FormLabel className="input-label">Nome:</FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="fullName"
                      defaultValue={user.fullName}
                      {...register("fullName", {
                        required: "Campo obrigatório",
                        pattern: {
                          value:
                            /^(([a-zA-Z\u00C0-\u00FF]{2,})+( [a-zA-Z\u00C0-\u00FF]+)+)$/gm,
                          message:
                            "Insira nome e sobrenome, somente letras e acentos.",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="fullName"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <>
                            <Text
                              fontSize="14px"
                              color="rgb(211, 66, 66)"
                              key={type}
                            >
                              {message}
                            </Text>
                          </>
                        ))
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">
                      Número de Celular
                    </FormLabel>
                    <Input
                      className="input-profile"
                      as={InputMask}
                      mask={"(99)99999-9999"}
                      maskChar={null}
                      type="text"
                      id="phone"
                      defaultValue={user.phone}
                      {...register("phone", {
                        required: "Campo obrigatório",
                        pattern: {
                          value: /(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/g,
                          message: "Número de celular inválido",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="phone"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Gênero:</FormLabel>
                    <Select
                        className="input-profile"
                        id="gender"
                        defaultValue={user.gender}
                        {...register("gender", {
                          required: "Campo obrigatório",
                        })}
                    >
                      <option value="" selected disabled>
                        {" "}
                        Selecione{" "}
                      </option>
                      <option value="Homem-cisgênero">Homem-cisgênero</option>
                      <option value="Mulher-cisgênro">Mulher-cisgênero</option>
                      <option value="Não-binário">Não-binário</option>
                      <option value="Mulher-trans<">Mulher-trans</option>
                      <option value="Homem-trans">Homem-trans</option>
                      <option value="Outro">Outro</option>
                    </Select>
                    <ErrorMessage
                        errors={errors}
                        name="gender"
                        render={({messages}) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                                <Text
                                    fontSize="14px"
                                    color="rgb(211, 66, 66)"
                                    key={type}
                                >
                                  {message}
                                </Text>
                            ))
                        }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">CEP:</FormLabel>
                    <Input
                      className="input-profile"
                      as={InputMask}
                      mask={"99999-999"}
                      maskChar={null}
                      type="text"
                      id="zip"
                      minLength={9}
                      defaultValue={user.zip}
                      {...register("zip", {
                        required: "Campo obrigatório",
                        pattern: {
                          value: /[0-9]{5}-[\d]{3}/g,
                          message: "CEP Inválido",
                        },
                      })}
                      onBlur={(e) => verifyCep(e.target.value)}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="zip"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">País:</FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="country"
                      minLength={3}
                      title={"Deve conter apenas letras"}
                      pattern={"^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ' ]+$"}
                      defaultValue={user.country}
                      {...register("country", {
                        required: "Campo obrigatório",
                        minLength: {
                          value: 2,
                          message: "Deve conter mais que 2 caracteres",
                        },
                        maxLength: {
                          value: 20,
                          message: "Limite máximo de 20 caracteres",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="country"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Estado:</FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="state"
                      minLength={2}
                      title={"Deve conter apenas letras"}
                      pattern={"^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ' ]+$"}
                      defaultValue={user.state}
                      {...register("state", {
                        required: "Campo obrigatório",
                        minLength: {
                          value: 2,
                          message: "Deve conter mais que 2 caracteres",
                        },
                        maxLength: {
                          value: 20,
                          message: "Limite máximo de 20 caracteres",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="state"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Cidade:</FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="city"
                      minLength={2}
                      defaultValue={user.city}
                      {...register("city", {
                        required: "Campo obrigatório",
                        minLength: {
                          value: 2,
                          message: "Deve conter mais que 2 caracteres",
                        },
                        maxLength: {
                          value: 50,
                          message: "Limite máximo de 50 caracteres",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="city"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Bairro:</FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="district"
                      minLength={2}
                      defaultValue={user.district}
                      {...register("district", {
                        required: "Campo obrigatório",
                        minLength: {
                          value: 2,
                          message: "Deve conter mais que 2 caracteres",
                        },
                        maxLength: {
                          value: 250,
                          message: "Limite máximo de 250 caracteres",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="district"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Rua:</FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="street"
                      minLength={2}
                      defaultValue={user.street}
                      {...register("street", {
                        required: "Campo obrigatório",
                        minLength: {
                          value: 2,
                          message: "Deve conter mais que 2 caracteres",
                        },
                        maxLength: {
                          value: 250,
                          message: "Limite máximo de 250 caracteres",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="street"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Número:</FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="number"
                      defaultValue={user.number}
                      {...register("number", {
                        required: "Campo obrigatório",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="number"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Complemento:</FormLabel>
                    <Input
                        className="input-profile"
                        type="text"
                        id="complement"
                        defaultValue={user.complement}
                        {...register("complement")}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">
                      Aeroporto mais próximo:
                    </FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="nearbyAirport"
                      title={"Deve conter apenas letras"}
                      defaultValue={user.nearbyAirport}
                      {...register("nearbyAirport", {
                        required: "Campo obrigatório",
                        minLength: {
                          value: 3,
                          message: "Deve conter mais que 3 caracteres",
                        },
                        maxLength: {
                          value: 240,
                          message: "Limite máximo de 240 caracteres",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="nearbyAirport"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Estado civil:</FormLabel>
                    <Select
                      className="input-profile"
                      id="maritalState"
                      defaultValue={user.maritalState}
                      {...register("maritalState", {
                        required: "Campo obrigatório",
                      })}
                    >
                      <option value="" selected disabled>
                        {" "}
                        Selecione{" "}
                      </option>
                      <option defaultValue="Solteiro(a)">Solteiro(a)</option>
                      <option defaultValue="Casado(a)">Casado(a)</option>
                      <option defaultValue="União Estável">União Estável</option>
                      <option defaultValue="Viúvo(a)">Viúvo(a)</option>
                      <option defaultValue="Divorciado(a)">Divorciado(a)</option>
                    </Select>
                    <ErrorMessage
                      errors={errors}
                      name="maritalState"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">
                      Possui filhos?
                    </FormLabel>
                    <Select
                      className="input-profile"
                      id="hasChildren"
                      defaultValue={user.hasChildren}
                      {...register("hasChildren", {
                        required: "Campo obrigatório",
                      })}
                    >
                      <option value="" selected disabled>
                        {" "}
                        Selecione{" "}
                      </option>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </Select>
                    <ErrorMessage
                      errors={errors}
                      name="hasChildren"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>

                  {depHasChildren === "true" && (
                    <>
                      <FormControl>
                        <FormLabel className="input-label">
                          Quantidade:
                        </FormLabel>
                        <Input
                          className="input-profile"
                          type="number"
                          id="childrenQty"
                          defaultValue={user.childrenQty}
                          {...register("childrenQty")}
                        />
                      </FormControl>
                    </>
                  )}

                  <FormControl>
                    <FormLabel className="input-label">Chave Pix:</FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="pixKey"
                      defaultValue={user.pixKey}
                      {...register("pixKey", {
                        required: "Campo obrigatório",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="pixKey"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
                  </FormControl>
                </SimpleGrid>
              </form>
            </TabPanel>

            <TabPanel>
              <form id="profileForm" onSubmit={handleSubmit(editar)}>
                <SimpleGrid
                  columns={["1", "1", "2", "3"]}
                  spacingY="20px"
                  spacingX="20px"
                  max-width="1000px"
                  margin="auto"
                  padding="0px"
                >
                  <FormControl>
                    <FormLabel className="input-label">Função:</FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="functionAtWork"
                      defaultValue={user.functionAtWork}
                      {...register("functionAtWork")}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Faz PED?</FormLabel>
                    <Select
                      className="input-profile"
                      id="ped"
                      defaultValue={user.ped}
                      {...register("ped")}
                    >
                      <option value="" selected disabled>
                        {" "}
                        Selecione{" "}
                      </option>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </Select>
                  </FormControl>

                  {depPed === "true" && (
                    <>
                      <FormControl>
                        <FormLabel className="input-label">
                          PED Responsável:
                        </FormLabel>
                        <Input
                          className="input-profile"
                          type="text"
                          id="responsiblePed"
                          defaultValue={user.responsiblePed}
                          {...register("responsiblePed")}
                        />
                      </FormControl>
                    </>
                  )}

                  <FormControl>
                    <FormLabel className="input-label">Faz Inglês?</FormLabel>
                    <Select
                      className="input-profile"
                      id="english"
                      defaultValue={user.english}
                      {...register("english")}
                    >
                      <option value="" selected disabled>
                        {" "}
                        Selecione{" "}
                      </option>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </Select>
                  </FormControl>

                  {depEnglish === "true" && (
                    <>
                      <FormControl>
                        <FormLabel className="input-label">
                          Professor(a) de inglês:
                        </FormLabel>
                        <Input
                          className="input-profile"
                          type="text"
                          id="englishTeacher"
                          defaultValue={user.englishTeacher}
                          {...register("englishTeacher")}
                        />
                      </FormControl>
                    </>
                  )}

                  <FormControl>
                    <FormLabel className="input-label">Faz Terapia?</FormLabel>
                    <Select
                      className="input-profile"
                      id="therapy"
                      defaultValue={user.therapy}
                      {...register("therapy")}
                    >
                      <option value="" selected disabled>
                        {" "}
                        Selecione{" "}
                      </option>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </Select>
                  </FormControl>

                  {depTherapy === "true" && (
                    <>
                      <FormControl>
                        <FormLabel className="input-label">
                          Terapeuta responsável:
                        </FormLabel>
                        <Input
                          className="input-profile"
                          type="text"
                          id="responsibleTherapist"
                          defaultValue={user.responsibleTherapist}
                          {...register("responsibleTherapist")}
                        />
                      </FormControl>
                    </>
                  )}

                  <FormControl>
                    <FormLabel className="input-label">Graduado(a)?</FormLabel>
                    <Select
                      className="input-profile"
                      id="undergraduate"
                      defaultValue={user.undergraduate}
                      {...register("undergraduate")}
                    >
                      <option value="" selected disabled>
                        {" "}
                        Selecione{" "}
                      </option>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </Select>
                  </FormControl>

                  {depUndergraduate === "true" && (
                    <>
                      <FormControl>
                        <FormLabel className="input-label">Curso:</FormLabel>
                        <Input
                          className="input-profile"
                          type="text"
                          id="undergraduateCourseName"
                          defaultValue={user.undergraduateCourseName}
                          {...register("undergraduateCourseName")}
                        />
                      </FormControl>
                    </>
                  )}

                  <FormControl>
                    <FormLabel className="input-label">
                      Pós-graduado(a)?
                    </FormLabel>
                    <Select
                      className="input-profile"
                      id="graduate"
                      defaultValue={user.graduate}
                      {...register("graduate")}
                    >
                      <option value="" selected disabled>
                        {" "}
                        Selecione{" "}
                      </option>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </Select>
                  </FormControl>

                  {depGraduate === "true" && (
                    <>
                      <FormControl>
                        <FormLabel className="input-label">Curso:</FormLabel>
                        <Input
                          className="input-profile"
                          type="text"
                          id="graduateCourseName"
                          defaultValue={user.graduateCourseName}
                          {...register("graduateCourseName")}
                        />
                      </FormControl>
                    </>
                  )}
                </SimpleGrid>
              </form>
            </TabPanel>

            <TabPanel>
              <form id="profileForm" onSubmit={handleSubmit(editar)}>
                <SimpleGrid
                  columns={["1", "1", "2", "3"]}
                  spacingY="20px"
                  spacingX="20px"
                  max-width="1000px"
                  margin="auto"
                  padding="0px"
                >
                  <FormControl>
                    <FormLabel className="input-label">
                      Tamanho da camisa:
                    </FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="shirtSize"
                      defaultValue={user.shirtSize}
                      {...register("shirtSize")}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">
                      Tamanho do calçado:
                    </FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="shoeSize"
                      defaultValue={user.shoeSize}
                      {...register("shoeSize")}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Cor favorita:</FormLabel>
                    <Input
                      className="input-profile"
                      type="text"
                      id="favouriteColor"
                      defaultValue={user.favouriteColor}
                      {...register("favouriteColor")}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">
                      Comida favorita:
                    </FormLabel>
                    <Textarea
                      className="input-profile"
                      type="text"
                      id="favouriteFood"
                      defaultValue={user.favouriteFood}
                      {...register("favouriteFood")}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Pets:</FormLabel>
                    <Textarea
                      className="input-profile"
                      type="text"
                      id="pets"
                      defaultValue={user.pets}
                      {...register("pets")}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">Hobbies:</FormLabel>
                    <Textarea
                      className="input-profile"
                      type="text"
                      id="hobbies"
                      defaultValue={user.hobbies}
                      {...register("hobbies")}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="input-label">
                      Campo ou praia:
                    </FormLabel>
                    <Select
                      className="input-profile"
                      id="beachOrCamp"
                      defaultValue={user.beachOrCamp}
                      {...register("beachOrCamp")}
                    >
                      <option value="" selected disabled>
                        {" "}
                        Selecione{" "}
                      </option>
                      <option value="Campo">Campo</option>
                      <option value="Praia">Praia</option>
                    </Select>
                  </FormControl>
                </SimpleGrid>
              </form>
            </TabPanel>

            <TabPanel>
              <NewPassword />
            </TabPanel>

            <TabPanel>
              <DayOffList dayoffList={dayOffs} />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Stack direction="row" float="right" pr={7}>
          <Button
            className="button"
            type="submit"
            hidden={index === 3 || index === 4 ? true : false}
            form="profileForm"
            isLoading={isButtonLoading}
            loadingText="Salvando"
          >
            Salvar Alterações
          </Button>
        </Stack>
      </div>

      <Footer />
    </>
  );
}
