import moment from 'moment/moment.js';

//Here a convert the received date from the form to the API format before send it.
export function formatation(date, dateEnd) {
    let dateFim;
    if (dateEnd !== '00-00-0000') {
        date = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        dateEnd = moment(dateEnd, 'DD-MM-YYYY').format('YYYY-MM-DD');
        dateFim = date + '/' + dateEnd
    } else if (date.length === 11) {
        dateFim = moment(date, 'DD-MM-YYYY/').format('YYYY-MM-DD');

    } else if (date.length === 10) {
        dateFim = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    } else {
        date = date.split('/')
        let date1 = date[0]
        let date2 = date[1]
        let date1Form = moment(date1, 'DD-MM-YYYY').format('YYYY-MM-DD')
        let date2Form = moment(date2, 'DD-MM-YYYY').format('YYYY-MM-DD')
        dateFim = date1Form + '/' + date2Form;
    }
    return dateFim
}
