import {Button, Stack} from '@chakra-ui/react'
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"

const Pagination = ({ page, onPageChange }) => {
    return (
            <div>
                    <Stack direction='row' spacing={3} float="right">
                    <Button leftIcon={<FaAngleLeft />}
                            colorScheme='blue'
                            variant="ghost"
                            isDisabled={page?.first}
                            onClick={() =>onPageChange(page?.number - 1)}
                    >
                        Anterior
                    </Button>

                    <Button variant='unstyled'>
                        Pag. {page?.number + 1}
                    </Button>

                    <Button rightIcon={<FaAngleRight />}
                            colorScheme='blue'
                            variant="ghost"
                            isDisabled={page?.last}
                            onClick={() =>onPageChange(page?.number + 1)}
                    >
                        Próxima
                    </Button>
                    </Stack>
            </div>
    )
}
export default Pagination;
