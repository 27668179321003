import React from "react";
import Datatable from "../../components/Datatable";
import { Header } from "../../components/Header";
import {Footer} from "../../components/Footer";

export default function Index() {
  return (
    <>
      <Header />
        <Datatable />
      <Footer/>
    </>
  )
}
