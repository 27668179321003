import {
  Avatar,
  Button,
  Flex, HStack, IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  SkeletonCircle,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {FaAngleDown, FaExternalLinkAlt} from "react-icons/fa";

import EmployeesService from "../services/EmployeesService";
import { logoutAndDestroyToken } from "../services/LoginService";
import {HamburgerIcon} from "@chakra-ui/icons";

export function Header() {
  const companyName = process.env.REACT_APP_COMPANY_NAME;
  const mobileLogo = require(`../assets/${companyName}/imgs/mobile-logo.png`);

  const navigate = useNavigate();
  const [informations, setInformations] = useState([]);
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [imgLink, setImgLink] = useState("");

  function fetchUserPicture() {
    
    const exists = localStorage.getItem("userProfilePicture")
    if(exists) {
      setImgLink(exists)
      return
    }
    
    EmployeesService.getProfilePicture().then((response) => {
      localStorage.setItem("userProfilePicture", response.data)
      setImgLink(response.data);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (role !== null) {
      setIsLoading(false);
    }
  }, [role]);

  useEffect(() => {
    const idUser = localStorage.getItem("idUser");
    EmployeesService.getEmployeeById(idUser).then((res) => {
      setInformations(res.data);
      setRole(res.data.roles[0].role);
    });
  }, []);

  useEffect(() => {
    fetchUserPicture();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="header-1">
          <div className="description-header">
            <Flex alignItems="center">
              <Skeleton height="50px" width="50px">
                <Image src="" alt="simbiose" />
              </Skeleton>

              <Spacer display={{ base: 'none', md: 'flex'}} />
              <Flex display={{ base: 'none', md: 'flex'}}>
                <HStack spacing='40px'>
                  <Skeleton>
                    <Text fontSize="sm"> Calendário </Text>
                  </Skeleton>
                  <Skeleton>
                    <Text fontSize="sm"> Membros </Text>
                  </Skeleton>
                  <Skeleton>
                    <Text fontSize="sm"> Aprovar Day Off </Text>
                  </Skeleton>
                </HStack>
              </Flex>

              <Spacer />

              <Flex justifyContent="center" alignItems="center" display={{ base: 'none', md: 'flex'}}>
                <Skeleton>
                  <Text mr={1} fontSize="sm" as="b" color="gray.600">
                    Nome Usuário
                  </Text>
                </Skeleton>
              </Flex>


              <Flex justifyContent="center" alignItems="center" display={{ base: 'flex', md: 'none'}}>
                <Skeleton>
                <Text mr={3} fontSize="sm" as="b" className="text-profile">
                  nome do usuário
                </Text>
                </Skeleton>
              </Flex>

              <Spacer display={{ base: 'flex', md: 'none'}} />
              <Flex display={{ base: 'flex', md: 'none'}}>
                <Skeleton>
                <Menu>
                  <MenuButton
                      as={IconButton}
                      aria-label='Options'
                      icon={<HamburgerIcon />}
                      variant='outline'
                  />
                </Menu>
                </Skeleton>
              </Flex>

            </Flex>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="header-1">
      <div className="description-header">

        <Flex alignItems="center">

          <Link href="/calendar">
            <Image
              src={mobileLogo}
              alt="simbiose"
              boxSize="60px"
              minW="60px"
            />
          </Link>

          <Spacer display={{ base: 'none', md: 'flex'}} />
          <Flex display={{ base: 'none', md: 'flex'}}>
            <HStack spacing='40px'>

                <Link href="/calendar" className="links">
                  <Button variant="unstyled" size="sm">
                    <Text className="text-color">
                      Calendário
                    </Text>
                  </Button>
                </Link>

                <Link href="/employees" className="links">
                  <Button variant="unstyled" size="sm">
                    <Text className="text-color">
                      Membros
                    </Text>
                  </Button>
                </Link>

            {role === "ROLE_ADMIN" && (
              <>
                <Link href="/dayoff-approval" className="links">
                  <Button variant="unstyled" size="sm">
                    <Text className="text-color">
                      Aprovar Day Off
                    </Text>
                  </Button>
                </Link>
              </>
            )}

            </HStack>
          </Flex>

          <Spacer />

          <Flex justifyContent="center" alignItems="center" display={{ base: 'none', md: 'flex'}}>

            <Text mr={1} fontSize="sm" as="b" className="text-profile big-text-comp">
              {informations.fullName}
            </Text>

            <Menu display={{ base: 'none', md: 'flex'}}>
              <MenuButton
                as={Button}
                cursor="pointer"
                minW="50px"
                rightIcon={<FaAngleDown />}
                colorScheme="blue"
                variant="ghost"
              >
                <Avatar
                  size="sm"
                  src={imgLink}
                />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => navigate("/profile")}>
                  Meu Perfil
                </MenuItem>
                <MenuItem onClick={logoutAndDestroyToken}>Logout</MenuItem>
              </MenuList>
            </Menu>

          </Flex>

          <Link href="/profile" className="links">
          <Flex justifyContent="center" alignItems="center" display={{ base: 'flex', md: 'none'}}>
            <Text mr={3} fontSize="sm" as="b" className="text-profile big-text" align="right">
              {informations.fullName}
            </Text>
            <Menu>
              <MenuButton>
                <Avatar
                    size="sm"
                    src={imgLink}
                />
              </MenuButton>
            </Menu>
          </Flex>
          </Link>


          <Spacer display={{ base: 'flex', md: 'none'}} />
          <Flex display={{ base: 'flex', md: 'none'}}>
            <Menu>
              <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='outline'
              />
              <MenuList>
                <Link href="/calendar" className="links">
                  <MenuItem>
                    Calendário
                  </MenuItem>
                </Link>
                <Link href="/employees" className="links">
                  <MenuItem >
                    Membros
                  </MenuItem>
                </Link>

                {role === "ROLE_ADMIN" && (
                    <>
                <Link href="/dayoff-approval" className="links">
                  <MenuItem>
                    Aprovar Day Off
                  </MenuItem>
                </Link>
                    </>
                )}

                <MenuItem onClick={() => navigate("/profile")}>
                  Meu Perfil
                </MenuItem>
                <MenuItem onClick={logoutAndDestroyToken}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Flex>

        </Flex>
      </div>
    </div>
  );
}
