import {Button, Stack, Box, MenuButton, Menu, MenuItem, MenuList} from '@chakra-ui/react';
import {FaAngleDown, FaFilePdf} from 'react-icons/fa';

import ExportFileService from "../services/ExportFileService";

export function ExportTableButtons() {
  function getPdf() {
      ExportFileService.exportPdf().then(response => {
        const blob = response.data
        const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = 'Colaboradores.pdf'
            alink.click()
      
      }
        )
      }
  

  function getExcel() {
      ExportFileService.exportExcel().then(response => {
        const blob = response.data
        const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = 'Colaboradores.xls'
            alink.click()
      
      }
        )
  }

  return (
    <>
        <Menu>
            <MenuButton as={Button} rightIcon={<FaAngleDown />} colorScheme="blue" variant="ghost">
                Exportar
            </MenuButton>
            <MenuList>
                <MenuItem onClick={getPdf}>PDF</MenuItem>
                <MenuItem onClick={getExcel}>EXCEL</MenuItem>
            </MenuList>
        </Menu>
    </>
  );
}
