import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import "moment-timezone";
import moment from "moment";
import ModalDayOff from "../../components/ModalDayOff";
import ModalWeekend from "../../components/ModalWeekend";
import ModalDeclineDayOff from "../../components/ModalDeclineDayOff";
import ModalDeleteDayOff from "../../components/ModalDeleteDayOff";
import {Header} from "../../components/Header";
import {api} from "../../services/AxiosService";
import {EVENTS_URL} from "../../endpoints/endpoints";
import $ from "jquery";
import {Footer} from "../../components/Footer";
import EmployeesService from "../../services/EmployeesService";
import {getAtestadoByDayoffId} from "../../services/DayoffService";

export default class DemoApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyss: "",
            value: "pt-br",
            show: false,
            showWeekend: false,
            declineDayOff: false,
            deleteDayoff: false,
            dateSelected: "",
            events: [],
            employees: [],
            typeDay: "DAYOFF",
            dayOffJustification: "",
            dayOffStartHour: "",
            dayOffColors: ["orange", "#3388FF", "#a70000"],
            switch: false,
            rangeSelected: "",
            declineReason: "",
            sickNoteLink: "",
            isLoadingData: false,
            dayOffStatus: "",
            role: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.clearStates = this.clearStates.bind(this);
        this.handleShowDecline = this.handleShowDecline.bind(this);
        this.handleShowDelete = this.handleShowDelete.bind(this);
        this.handleEventClick = this.handleEventClick.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.resetedStates = this.resetedStates.bind(this)
    }

    // Here I use a request to get all created dayoffsc
    componentDidMount() {
        const companyName = process.env.REACT_APP_COMPANY_NAME;
        import(`./${companyName}/style.css`)
            .then(() => {
                this.setState({ isStyleLoaded: true });

                // Adiciona a legenda após o carregamento do CSS
                $(".fc .fc-toolbar.fc-header-toolbar").after(
                    "\
                      <div class='label'>\
                         <div>\
                            <div><i class='fa-square-pendente'></i></div>\
                            <div><p style='color: #ea8e36'>&nbspPendente&nbsp</p></div>\
                         </div>\
                         \
                          <div>\
                              <div><i class='fa-square-aprovado'></i></div>\
                              <div><p style='color: #3388FF'>&nbspAprovado&nbsp</p></div>\
                          </div>\
                          \
                         <div>\
                             <div><i class='fa-square-rejeitado'></i></div>\
                              <div><p style='color: #a70000'>&nbspRejeitado&nbsp</p></div>\
                          </div>\
                      </div>\
                  "
                );

                $(".fc-event-main").css({"background-color": "black", color: "#a70000"});
                this.getEvents();
            })
            .catch(err => {
                console.error('Erro ao carregar CSS:', err);
                this.setState({ loadingError: true });
            });

        this.getAllEmployees();
        this.getRole();
    }


    checkDevice() {
        if ((navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        )) {
            return true
        }
    }

    getEvents() {
        this.setState({events: []});
        api
            .get(EVENTS_URL, {})
            .then((response) => {
                const dados = response.data;
                dados.forEach((dado) => {
                    this.state.events.push({
                        start:
                            dado.startTime !== null
                                ? moment(
                                    dado.date + "T" + dado.startTime,
                                    "YYYY-MM-DD'T'HH:mm:ss"
                                ).format("YYYY-MM-DDTHH:mm:ss-03:00")
                                : null,
                        end:
                            dado.endTime !== null
                                ? moment(
                                    dado.date + "T" + dado.endTime,
                                    "YYYY-MM-DD'T'HH:mm:ss"
                                ).format("YYYY-MM-DDTHH:mm:ss-03:00")
                                : null,
                        id: dado.eventId,
                        title: dado.cardTitle,
                        date: dado.date,
                        color:
                            dado.status === "PENDING"
                                ? "orange"
                                : dado.status === "REJECTED"
                                    ? "#a70000"
                                    : "#3388FF",
                        display: "event",
                    });
                });
                this.setState({events: [...this.state.events]});
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getAllEmployees() {
        EmployeesService.getAllEmployeesAtOnce().then((response) => {
            let dados = response.data.content;
            this.setState({employees: dados});
        });
    }

    getRole() {
        const idUser = localStorage.getItem("idUser");
        EmployeesService.getEmployeeById(idUser).then((response) => {
            this.setState({role: response.data.roles[0].role});
        });
    }

    //Here I use this function to close the modal and clear the states
    clearStates(e) {
        if (this.state.declineDayOff === true) {
            this.setState({
                declineDayOff: false,
            });
            if (e === 'declined') {
                this.resetedStates()
            }
        } else if (this.state.deleteDayoff === true) {
            this.setState({deleteDayoff: false})
            if (e === "deleted") {
                this.resetedStates()
            }
        } else {
            this.resetedStates()
        }
    }

    resetedStates() {
        this.setState({
            deleteDayoff: false,
            show: false,
            showWeekend: false,
            declineDayOff: false,
            keyss: "",
            value: "pt-br",
            dateSelected: "",
            typeDay: "DAYOFF",
            dayOffJustification: "",
            dayOffStartHour: "",
            editDate: "",
            switch: false,
            rangeSelected: "",
            declineReason: "",
            sickNoteLink: "",
            dayOffStatus: ""
        });
        this.getAllEmployees();
    }

    //Here I show the modal in update and create cases
    handleShow(props, keyssType) {
        if (props === "weekend") {
            this.setState({showWeekend: true});
        } else if (props) {
            this.setState({
                keyss: keyssType,
                show: true,
                dateSelected: props.dayOffDate,
                typeDay: props.dayOffTypee,
                dayOffJustification: props.dayOffJustification,
                dayOffStartHour: props.dayOffStartHour,
                declineReason: props.declineReason,
                dayOffStatus: props.dayOffStatus
            });
        } else {
            this.setState({show: true});
        }
    }

    handleShowDecline() {
        this.setState({declineDayOff: true});
    }

    handleShowDelete() {
        this.setState({deleteDayoff: true});
    }

    //Here I use this function to change the Calendar Idiom
    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleChangeSwitch(event) {
        this.setState({switch: event.target.checked});
    }

    //Here I get all information about the clicked event and open it
    handleEventClick = (clickInfo) => {
        this.setState({isLoadingData: true})
        let eventId = clickInfo.event.id;
        localStorage.setItem("EventId", eventId);
        getAtestadoByDayoffId(eventId)
            .then((res) => {
                let link = res.data
                this.setState({sickNoteLink: link})
            })
            .catch((error) => {
            }).finally(() => this.setState({isLoadingData: false}))

        api
            .get(EVENTS_URL + eventId, {})
            .then((response) => {
                const dados = response.data;
                let DayoffDateReceived = dados.date;
                let idUserLocalStorage = localStorage.getItem("idUser");
                localStorage.setItem("idUserEvent", dados.idUser);
                let idUser = dados.idUser;
                let DayOffDateShow = moment(DayoffDateReceived).format("DD-MM-YYYY");
                let userDayoffData = {
                    dados: response.data,
                    status: dados.status,
                    dayOffTypee: dados.typeDay,
                    dayOffDate: DayOffDateShow,
                    dayOffStartHour: dados.startTime,
                    dayOffJustification: dados.justification,
                    declineReason: dados.reason,
                    dayOffStatus: dados.status
                };

                if (this.state.role === "ROLE_ADMIN") {
                    let keyssType = "ADMIN";
                    this.handleShow(userDayoffData, keyssType);
                } else if (idUserLocalStorage === idUser) {
                    let keyssType = "USER";
                    this.handleShow(userDayoffData, keyssType);
                } else {
                    let keyssType = "onlyView";
                    this.handleShow(userDayoffData, keyssType);
                }

                // Here I order the first name in the select of modal to be the user event clicked
                let idUserEvent = localStorage.getItem("idUserEvent");
                let orderedEmployee = this.state.employees.sort((employee) => {
                    return employee.idUser === idUserEvent ? -1 : 1;
                });

                this.setState({employees: orderedEmployee});
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    state = {
        weekendsVisible: true,
        currentEvents: [],
    };

    render() {

        const { isStyleLoaded} = this.state;

        if (isStyleLoaded) {
            return (
                <>
                    <Header/>

                    <div className="calendar">
                        <FullCalendar
                            longPressDelay={400}
                            locales={allLocales}
                            locale={this.state.value}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={{
                                left: "title",
                                right: "prev,next today dayGridMonth,timeGridWeek,timeGridDay",
                            }}
                            events={this.state.events}
                            initialView={this.checkDevice() === true ? "timeGridWeek" : "dayGridMonth"}
                            allDay={false}
                            editable={false}
                            selectable={true}
                            select={(info) => {
                                // Here I show the logged ADMIN name in the modal select
                                let idUser = localStorage.getItem("idUser");
                                let orderedEmployee = this.state.employees.sort((employee) => {
                                    return employee.idUser === idUser ? -1 : 1;
                                });
                                this.setState({employees: orderedEmployee});

                                //Here I receive the start and end dates when I select a date in the calendar
                                let endDay = info.endStr;
                                let startDay = info.start;

                                //Here I format the dates to be showing to the users only
                                let start = moment(startDay).format("DD-MM-YYYY");
                                let end = moment(endDay).subtract(1, "days").format("DD-MM-YYYY");

                                var dateRange;
                                if (startDay.getDay() === 0 || startDay.getDay() === 6) {
                                    let keyssType = "weekend";
                                    this.handleShow(keyssType);
                                } else {
                                    if (start === end) {
                                        dateRange = start;
                                    } else if (info.allDay === false) {
                                        let startHour = moment(startDay).format("HH:mm:ss");
                                        this.setState({
                                            typeDay: "HALF_DAYOFF",
                                            dayOffStartHour: startHour,
                                        });
                                        dateRange = start;
                                    } else {
                                        dateRange = start + "/" + end;
                                        this.setState({rangeSelected: true});
                                    }
                                    // if (this.state.role === "ROLE_ADMIN") {
                                    //     this.setState({keyss: "ADMIN"})
                                    // }
                                    this.setState({
                                        dateSelected: dateRange
                                    })
                                    this.handleShow();
                                }
                            }}
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={this.state.weekendsVisible}
                            eventContent={renderEventContent} // custom render function
                            eventClick={this.handleEventClick}
                            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                        />
                        <Footer/>
                    </div>

                    <ModalDayOff
                        getEvents={this.getEvents}
                        employees={this.state.employees}
                        role={this.state.role}
                        rangeSelected={this.state.rangeSelected}
                        switch={this.state.switch}
                        keyss={this.state.keyss}
                        show={this.state.show}
                        handleShowDecline={this.handleShowDecline}
                        handleShowDelete={this.handleShowDelete}
                        clearStates={this.clearStates}
                        typeDay={this.state.typeDay}
                        handleChange={(e) => this.setState({typeDay: e.target.value})}
                        handleChangeSwitch={(e) => this.setState({switch: e.target.checked})}
                        dateSelected={this.state.dateSelected}
                        dayOffStartHour={this.state.dayOffStartHour}
                        dayOffJustification={this.state.dayOffJustification}
                        declineReason={this.state.declineReason}
                        sickNoteLink={this.state.sickNoteLink}
                        isLoadingData={this.state.isLoadingData}
                        dayOffStatus={this.state.dayOffStatus}
                    />

                    <ModalWeekend
                        showWeekend={this.state.showWeekend}
                        clearStates={this.clearStates}
                    />

                    <ModalDeclineDayOff
                        declineDayOff={this.state.declineDayOff}
                        getEvents={this.getEvents}
                        clearStates={this.clearStates}
                    />

                    <ModalDeleteDayOff
                        deleteDayoff={this.state.deleteDayoff}
                        clearStates={this.clearStates}
                        getEvents={this.getEvents}
                    />
                </>
            );
        }
    }

    handleEvents = (events) => {
        this.setState({
            currentEvents: events,
        });
    };
}

function renderEventContent(eventInfo) {
    return (
        <>
            <i>{eventInfo.event.title}</i>
        </>
    );
}
