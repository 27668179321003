import {
  Alert,
  AlertDescription,
  AlertIcon, Avatar, Box,
  Button, Card, CardBody, CardFooter, CardHeader,
  Flex,
  FormControl,
  FormLabel, Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "preact/hooks";
import React, { useState } from "react";
import { getDayOffByUserId } from "../services/GetDayOffsByUser";
import { VscCircleFilled } from "react-icons/vsc";
import AuxiliaryService from "../services/AuxiliaryService";
import { useForm } from "react-hook-form";
import { ExternalLinkIcon, ViewIcon } from "@chakra-ui/icons";
import  InputMask  from "react-input-mask";
import { getAtestadoByDayoffId, updateDayOff } from "../services/DayoffService";
import UpDateDayOff from "../services/UpDateDayOff";
import { toast } from "react-toastify";
import {DayOffApprovalModal} from "./DayOffApprovalModal";

export function DayOffList(props) {

  const [loadingUpdateDayoffButton, setLoadingUpdateDayoffButton] =
      useState(false);
  const [atestadoLink, setAtestadoLink] = useState("");
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    register: register,
    handleSubmit: handleSubmit,
    setValue: setValue,
    watch: watch,
    reset,
    formState: { errors1 },
  } = useForm({ criteriaMode: "all" });

  const [dayOffToEdit, setDayoffToEdit] = useState([]);
  const [showDocument, setShowDocument] = useState(false);
  function openNewWindow(url) {
    window.open(url, "_blank", "noreferrer");
  }

  function getAtestadoLink(eventId) {
    getAtestadoByDayoffId(eventId)
        .then((res) => {
          setAtestadoLink(res.data);
        })
        .catch()
        .finally();
  }

  const updateDayoff = (dayoff) => {
    setLoadingUpdateDayoffButton(true);
    dayoff.status = "PENDING";
    const americanDate = AuxiliaryService.formatarDataPadraoAmericano(
        dayoff.date
    );

    const arquivo = dayoff.file === undefined ? undefined : dayoff.file[0]


    dayoff.date = americanDate;
    const formData = new FormData();
    formData.append(
        "updateEventsDto",
        new Blob([JSON.stringify(dayoff)], {
          type: "application/json",
        })
    );
    if (!arquivo) {
      formData.append("arquivo", new Blob(["teste"], { type: "text-plain" }));
    } else {
      formData.append("arquivo", arquivo);
    }

    UpDateDayOff(dayOffToEdit.eventId, formData)
        .then((res) => {
          toast.success(res.data);
        })
        .catch((err) => {
          toast.error(err.response.data);
        })
        .finally(() => {
          setLoadingUpdateDayoffButton(false);
        });
  };

  function checkDevice() {
    if ((navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    )) {
      return true
    } else {
      return false
    }
  }

  return (
      <>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Detalhes do Dayoff</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form id="update-dayoff" onSubmit={handleSubmit(updateDayoff)}>
                <FormControl>
                  <FormLabel className="input-label">Tipo do Dayoff:</FormLabel>
                  <Select
                      className="input-profile"
                      value={dayOffToEdit.typeDay}
                      {...register("typeDay")}
                  >
                    <option value="DAYOFF">Dayoff</option>
                    <option value="HALF_DAYOFF">Meio Dayoff</option>
                  </Select>
                </FormControl>
                {dayOffToEdit.typeDay === "HALF_DAYOFF" ? (
                    <>
                      <FormControl>
                        <FormLabel className="input-label">Início:</FormLabel>
                        <Input
                            type="time"
                            min="07:00:00"
                            max="16:00:00"
                            step="1"
                            defaultValue={dayOffToEdit.startTime}
                            {...register("startTime")}
                        />
                      </FormControl>
                    </>
                ) : null}

                <FormControl>
                  <FormLabel className="input-label">Data Início:</FormLabel>
                  <Input
                      as={InputMask}
                      mask="99/99/9999"
                      maskChar={null}
                      defaultValue={AuxiliaryService.formatarDataParaLocal(
                          dayOffToEdit.date
                      )}
                      {...register("date")}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Justificativa:</FormLabel>
                  <Textarea
                      {...register("justification")}
                      defaultValue={dayOffToEdit.justification}
                  />
                </FormControl>

                {dayOffToEdit.hasAtestado ? (
                    <>
                      <Alert status="warning" mt={5}>
                        <Flex flexDir="column">
                          <Flex>
                            <AlertIcon />

                            <AlertDescription>
                              Este dayoff possui documento anexado, clique no botão
                              abaixo para visualizar.
                            </AlertDescription>
                          </Flex>
                          <Flex justifyContent="center">
                            <Button
                                className="button"
                                mt={2}
                                rightIcon={<ExternalLinkIcon />}
                                onClick={() => openNewWindow(atestadoLink)}
                            >
                              Visualizar documento
                            </Button>
                          </Flex>
                        </Flex>
                      </Alert>
                    </>
                ) : null}

                <FormControl mt={3} mb={3}>
                  <Flex alignItems="center">
                    <FormLabel className="input-label">
                      {dayOffToEdit.hasAtestado
                          ? "Deseja reenviar o atestado?"
                          : "Deseja enviar atestado?"}
                    </FormLabel>
                    <Switch
                        onChange={() => {
                          showDocument === false
                              ? setShowDocument(true)
                              : setShowDocument(false);
                        }}
                    />
                  </Flex>
                </FormControl>

                {showDocument ? (
                    <FormControl>
                      <Input type="file" {...register("file")} />
                    </FormControl>
                ) : null}
              </form>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" form="update-dayoff" className="display-modal-button">
                Atualizar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <div className="dayoff-list-adjust">
          <Box display={{ base: 'none', md: 'flex'}}>
            <div className="table-responsivo">
              <table>
                <thead>
                <tr>
                  <td>Data</td>
                  <td>Status</td>
                  <td></td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                {!checkDevice() ? props.dayoffList?.map((dayOff) => (
                    <tr key={dayOff.eventId}>
                      <td>{AuxiliaryService.formatarDataParaLocal(dayOff.date)}</td>
                      <td>
                        {dayOff.status === "PENDING" ? (
                            <Flex alignItems="center">
                              <Icon color="orange" as={VscCircleFilled} />
                              <Text>Pendente</Text>
                            </Flex>
                        ) : dayOff.status === "REJECTED" ? (
                            <Flex alignItems="center">
                              <Icon color="#a70000" as={VscCircleFilled} />
                              <Text>Rejeitado</Text>
                            </Flex>
                        ) : dayOff.status === "EMERGENCY" ? (
                            <Flex alignItems="center">
                              <Icon color="#8A2BE2" as={VscCircleFilled} />
                              <Text>Emergência</Text>
                            </Flex>
                        ) : dayOff.status === "JUSTIFIED" ? (
                            <Flex alignItems="center">
                              <Icon color="#15803d" as={VscCircleFilled} />
                              <Text>Justificado</Text>
                            </Flex>
                        ) : (
                            <Flex alignItems="center">
                              <Icon color="#3388FF" as={VscCircleFilled} />
                              <Text>Aprovado</Text>
                            </Flex>
                        )}
                      </td>
                      <td>
                        <Button onClick={
                          () => {
                            setDayoffToEdit(dayOff)
                            getAtestadoLink(dayOff.eventId);
                            reset()
                            onOpen()
                          }
                        } className="display-modal-button" leftIcon={<ViewIcon />}>Detalhes</Button>
                      </td>
                      <td></td>
                    </tr>
                )) : null}
                </tbody>
              </table>
            </div>
          </Box>
        </div>

        <div className="cards-adjust">
          {checkDevice() ? props.dayoffList?.map((dayOff) => (
              <Card maxW='lg' mb={5} bg="white" boxShadow='base' display={{ base: 'flex', md: 'none'}} key={dayOff.eventId}>
                <CardHeader>
                  <Flex spacing='4'>

                    <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                      <Box>
                        <Heading size='sm'>
                          Seu {String(dayOff.typeDay === "HALF_DAYOFF" ? "MEIO DAYOFF" : "DAYOFF")} para&nbsp;
                          {AuxiliaryService.formatarDataParaLocal(dayOff.date)}
                          {dayOff.typeDay === "HALF_DAYOFF" ? (
                              <>
                                &nbsp;a partir das {AuxiliaryService.formatToLocalHour(dayOff.startTime)}
                              </>
                          ) : null}
                          &nbsp;está:
                        </Heading>
                        <Flex>
                          {dayOff.status === "PENDING" ? (
                              <Flex alignItems="center">
                                <Icon color="orange" as={VscCircleFilled} />
                                <Text>Pendente</Text>
                              </Flex>
                          ) : dayOff.status === "REJECTED" ? (
                              <Flex alignItems="center">
                                <Icon color="#a70000" as={VscCircleFilled} />
                                <Text>Rejeitado</Text>
                              </Flex>
                          ) : dayOff.status === "EMERGENCY" ? (
                              <Flex alignItems="center">
                                <Icon color="#8A2BE2" as={VscCircleFilled} />
                                <Text>Emergência</Text>
                              </Flex>
                          ) : dayOff.status === "JUSTIFIED" ? (
                              <Flex alignItems="center">
                                <Icon color="#15803d" as={VscCircleFilled} />
                                <Text>Justificado</Text>
                              </Flex>
                          ) : (
                              <Flex alignItems="center">
                                <Icon color="#3388FF" as={VscCircleFilled} />
                                <Text>Aprovado</Text>
                              </Flex>
                          )}
                        </Flex>
                      </Box>
                    </Flex>

                  </Flex>

                </CardHeader>

                {dayOff.reason !== null ? (
                    <>
                      <CardBody>
                        <Text fontSize='lg'>Resposta: </Text>
                        <Text>{dayOff.reason}</Text>
                      </CardBody>
                    </>
                ) : null}

                <CardFooter
                    justify='space-between'
                    flexWrap='wrap'
                >
                  <Button onClick={
                    () => {
                      setDayoffToEdit(dayOff)
                      getAtestadoLink(dayOff.eventId);
                      reset()
                      onOpen()
                    }
                  } className="card-edit-button" leftIcon={<ViewIcon />}>Detalhes</Button>
                </CardFooter>

              </Card>
          )) : null}
        </div>

      </>
  );
}

