import "../assets/simbiose/css/style.css";

import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { useDisclosure } from "@chakra-ui/hooks";
import { ExternalLinkIcon, ViewIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import { Flex, Link, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Textarea } from "@chakra-ui/textarea";
import { ErrorMessage } from "@hookform/error-message";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import AuxiliaryService from "../services/AuxiliaryService";
import { getAtestadoByDayoffId, updateDayOff } from "../services/DayoffService";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Spinner,
} from "@chakra-ui/react";

export function DayOffApprovalModal(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [hasAtestado, setHasAtestado] = useState(false);
  const [atestadoLink, setAtestadoLink] = useState("");
  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ criteriaMode: "all" });

  const approveDayOff = (dayOff) => {
    const dayOffUpdate = {
      idUser: props.dayOff.idUser,
      statusDayOff: "APPROVED",
      eventId: props.dayOff.eventId,
    };

    updateDayOff(dayOffUpdate).then((response) => {
      toast.success(response.data);
      props.fetchUsers(props.status);
      onClose();
    });
  };

  const declineDayOff = (dayOff) => {
    const dayOffUpdate = {
      idUser: props.dayOff.idUser,
      statusDayOff: "REJECTED",
      reason: dayOff.reason,
      eventId: props.dayOff.eventId,
    };

    updateDayOff(dayOffUpdate).then((response) => {
      toast.success(response.data);
      props.fetchUsers(props.status);
      onCloseReject();
      onClose();
    });
  };

  const justifyDayOff = (dayOff) => {
    const dayOffUpdate = {
      idUser: props.dayOff.idUser,
      statusDayOff: "JUSTIFIED",
      eventId: props.dayOff.eventId,
    };

    updateDayOff(dayOffUpdate).then((response) => {
      toast.success(response.data);
      props.fetchUsers(props.status);
      onClose();
    });
  };

  function getAtestadoByEventId() {
    getAtestadoByDayoffId(props.dayOff.eventId)
        .then((res) => {
          setAtestadoLink(res.data)
          setHasAtestado(true)
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .finally(() => {
          setIsLoadingData(false);
        });
  }

  function openNewWindow(url) {
    window.open(url, '_blank', 'noreferrer');
  }

  const isEmergency = props.dayOff.emergency;

  return (
      <>
        <Button
            leftIcon={<ViewIcon />}
            colorScheme="blue"
            className={props.buttonClassName}
            onClick={() => {
              getAtestadoByEventId();
              onOpen();
            }}
        >
          Detalhes
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Aprovação dayoff</ModalHeader>

            {isLoadingData ? (
                <ModalBody>
                  <Flex
                      justifyContent="center"
                      alignItems="center"
                      height="229px"
                      flexDir="column"
                  >
                    <Spinner size="lg" />
                    <Text mt={2}>Carregando dados...</Text>
                  </Flex>
                </ModalBody>
            ) : (
                <>
                  <ModalBody>
                    <form>
                      <FormControl>
                        <FormLabel className="input-label">
                          Tipo do Dayoff
                        </FormLabel>
                        <Input
                            className="modal-input"
                            type="text"
                            value={props.dayOff.typeDay}
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel className="input-label">Data</FormLabel>
                        <Input
                            className="modal-input"
                            type="text"
                            value={AuxiliaryService.formatarDataParaLocal(
                                props.dayOff.date
                            )}
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel className="input-label">Justificativa</FormLabel>
                        <Textarea
                            className="modal-input"
                            type="text"
                            value={props.dayOff.justification}
                            disabled
                        />
                      </FormControl>
                      {hasAtestado ? (
                          <Alert status="warning" mt={5}>
                            <Flex flexDir="column">
                              <Flex>
                                <AlertIcon />

                                <AlertDescription>
                                  Este dayoff possui documento anexado, clique no
                                  botão abaixo para visualizar.
                                </AlertDescription>
                              </Flex>
                              <Flex justifyContent="center">
                                <Button className="button" mt={2} rightIcon={<ExternalLinkIcon />} onClick={
                                  () => openNewWindow(atestadoLink)
                                }>Visualizar documento</Button>
                              </Flex>
                            </Flex>
                          </Alert>
                      ) : null}
                    </form>
                  </ModalBody>
                  <ModalFooter>
                    {!isEmergency ? (
                        <>
                          <Button
                              className="modal-cancel-button"
                              value="reject"
                              onClick={() => {
                                reset();
                                onOpenReject();
                              }}
                          >
                            Rejeitar
                          </Button>
                          <Button
                              className="modal-submit-button"
                              form="dayOff"
                              onClick={() => {
                                approveDayOff();
                              }}
                          >
                            Aprovar
                          </Button>
                        </>
                    ) : (
                        <>
                          <Button
                              className="modal-submit-button"
                              ml={2}
                              onClick={() => {
                                approveDayOff();
                              }}
                          >
                            Aprovar
                          </Button>
                          <Button
                              className="modal-justify-button"
                              ml={2}
                              onClick={() => {
                                justifyDayOff();
                              }}
                          >
                            Justificar
                          </Button>
                        </>
                    )}
                  </ModalFooter>
                </>
            )}
          </ModalContent>
        </Modal>

        <Modal isOpen={isOpenReject} onClose={onCloseReject}>
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>Rejeitar dayoff</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <form id="rejectDayOff" onSubmit={handleSubmit(declineDayOff)}>
                  <FormControl>
                    <FormLabel>Insira sua justificativa:</FormLabel>
                    <Textarea
                        type="text"
                        {...register("reason", { required: "Campo obrigatório" })}
                    />
                  </FormControl>
                  <ErrorMessage
                      errors={errors}
                      name="reason"
                      render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                              <Text fontSize="14px" color="rgb(211, 66, 66)" key={type}>
                                {message}
                              </Text>
                          ))
                      }
                  />
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                    className="modal-cancel-button"
                    mr={2}
                    onClick={onCloseReject}
                >
                  Cancelar
                </Button>

                <Button
                    className="modal-submit-button"
                    type="submit"
                    form="rejectDayOff"
                >
                  Rejeitar
                </Button>
              </ModalFooter>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      </>
  );
}
