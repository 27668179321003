import axios from "axios";
import { BASE_URL } from "../endpoints/endpoints";

let isDiplayAlert = false;

export const api = axios.create({
  baseURL: BASE_URL,
});

//intercepa as requisições antes de enviar e adiciona o token salvo no localStorage no header
api.interceptors.request.use(async (config) => {
  try {
    const token = await localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  } catch (error) {
    console.log(error);
  }
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      if(!isDiplayAlert) {
        isDiplayAlert = true;
        alert("Credencias expiradas, realize o login novamente!");
        window.location.href = process.env.REACT_APP_LOGOUT_URL;
      }
    }

    if (error.response.status === 403) {
      if(!isDiplayAlert) {
        isDiplayAlert = true;
        alert("Você não tem permissão para acessar esta página!");
        window.location.href = process.env.REACT_APP_LOGOUT_URL;
      }
    }

    return Promise.reject(error);
  }
);
