import {
  Avatar,
  Box,
  Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Flex,
  FormLabel, Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement, Skeleton,
  Spacer,
  Switch,
  Tag,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaPlus, FaSearch, FaEllipsisV } from "react-icons/fa";

import { api } from "../services/AxiosService";
import EmployeesService from "../services/EmployeesService";
import { ModalEmployees } from "./ModalEmployees";
import Pagination from "./Pagination";
import { USERS_URL } from "../endpoints/endpoints";
import { FormControl } from "@chakra-ui/form-control";
import {toast} from "react-toastify";
import {ExportTableButtons} from "./ExportTableButtons";
import {ResendEmailButton} from "./ResendEmailButton";

export default function Datatable(props) {
  const companyName = process.env.REACT_APP_COMPANY_NAME;
  const [queryName, setQueryName] = useState("");
  const [page, setPage] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const changePage = (number) => {
    setActivePage(number);
  };
  const [switchText, setSwitchText] = useState(true)
  const [informations, setInformations] = useState([]);
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    if (role !== null) {
      setIsLoading(false);
    }
  }, [role]);

  useEffect(() => {
    function getInformations() {
      const idUser = localStorage.getItem("idUser");
      EmployeesService.getEmployeeById(idUser).then((response) => {
        setInformations(response.data);
        setRole(response.data.roles[0].role);
      });
    }
    getInformations();
  }, []);

  function fetchAllEmployees() {
    EmployeesService.getAllEmployees(activePage).then((response) => {
      setPage(response.data);
    });
  }

  useEffect(() => {
    const fetchName = async () => {
      const res = await api.get(`${USERS_URL}?keyword=${queryName}`);
      setPage(res.data);
    };
    if (queryName.length >= 3) {
      fetchName();
    } else if (queryName.length === 0) {
      EmployeesService.getAllEmployees(activePage).then((response) => {
        setPage(response.data);
      });
    }
  }, [queryName]);

  useEffect(() => {
    fetchAllEmployees();
  }, [activePage]);

  function findUsersByEnabled(enabled) {
    if(!enabled) {
      api.get(`${USERS_URL}?page=${activePage}&enabled=${enabled}`).then(res => {
        setSwitchText(enabled)
        setPage(res.data)
      })
    } else {
      setSwitchText(enabled)
      fetchAllEmployees()
    }
  }

  function emailResend(email) {
    EmployeesService.resendEmail(email).then(res => {
      toast.success(res.data)
    }).catch(error => {
      toast.error(error.response.data)
    }).finally(() => {
      setIsButtonLoading(false);
    })
  }

  function checkDevice() {
    if ((navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    )) {
      return true
    } else {
      return false
    }
  }

  if (isLoading) {
    return (
        <>
          <div className="description-title-b">
            <div className="description-title">
              <Box align='left'>
                <Skeleton>
                  <Heading as='h3' size='lg' mb={4}>
                    {companyName === 'simbiose' ? 'Colaboradores da Simbiose' : 'Colaboradores do Grupo MNGT'}
                  </Heading>
                </Skeleton>
              </Box>

              <HStack spacing={6}>

                <Skeleton>
                  <Button leftIcon={<FaPlus />} className="display-modal-button">
                    <ModalEmployees
                        buttonText={"Novo Colaborador"}
                    />
                  </Button>
                </Skeleton>

                <Skeleton>
                  <ExportTableButtons />
                </Skeleton>

              </HStack>
            </div>
          </div>

          <div className="description-box">

            <Flex minWidth='200px' alignItems='center' gap='2' mb={6}>
              <Skeleton>
              <InputGroup focusBorderColor="blue">
                <InputLeftElement
                    pointerEvents="none"
                />
                <Input
                    bg="white"
                    boxShadow='base'
                    type="search"
                    placeholder="Pesquisar"
                />
              </InputGroup>
              </Skeleton>

              <Spacer />

              <Skeleton>
              <FormControl display='flex' alignItems='center' justifyContent="flex-end">
                <FormLabel mb='0'>
                  <Text>Usuários Ativos</Text>
                </FormLabel>
                <Switch size='lg' defaultChecked={true} />
              </FormControl>
              </Skeleton>

            </Flex>

            <Skeleton>
              <Box w='100%' h='400px'></Box>
            </Skeleton>

          </div>

        </>
    );
  }

  return (
    <>
      <div className="description-title-b">
        <div className="description-title">
          <Box align='left' w="100%">
            <Heading as='h3' size='lg' mb={4} className="text-color">
              {companyName === 'simbiose' ? 'Colaboradores da Simbiose' : 'Colaboradores do Grupo MNGT'}
            </Heading>
          </Box>
          {role !== "ROLE_ADMIN" ? null : (
              <>
                <HStack spacing={2}>
                  <Button leftIcon={<FaPlus />} className="display-modal-button">
                    <ModalEmployees
                        modalTitle={"Cadastrar"}
                        buttonText={"Novo Colaborador"}
                        buttonName={"Salvar"}
                        buttonClassName={"display-modal-button"}
                        fetchAllEmployees={fetchAllEmployees}
                    />
                  </Button>
                  <ExportTableButtons />
                </HStack>
              </>
          )}
        </div>
      </div>

      <div className="description-box">

        <Flex flexDir={{base: "column", md: "row"}} minWidth='200px' alignItems='center' gap='2'>
          <InputGroup focusBorderColor="blue">
            <InputLeftElement
              pointerEvents="none"
              children={<FaSearch color="gray.600" />}
            />
            <Input
              bg="white"
              boxShadow='base'
              type="search"
              placeholder="Pesquisar"
              onChange={(e) => setQueryName(e.target.value)}
            />
          </InputGroup>

          <Spacer />

          <FormControl display='flex' alignItems='center' justifyContent={{base: "flex-start", md: "flex-end"}}>
            <FormLabel mb='0'>
              {switchText === true ?
                  <Text fontSize="sm" align="right">Usuários Ativos</Text> :
                  <Text fontSize="sm" align="right">Usuários Inativos</Text>}
            </FormLabel>
            <Switch size='lg' defaultChecked={true} onChange={(e) => findUsersByEnabled(e.target.checked)} />
          </FormControl>

        </Flex>

        <Box display={{ base: 'none', md: 'flex'}}>
        <div className="table-responsivo">
          <table>
            <thead className="text-color">
              <tr>
                <td>Nome</td>
                <td>Celular</td>
                <td>Chave PIX</td>
                <td>Dayoffs</td>
                {role === "ROLE_ADMIN" && <td>Salário</td>}
                <td>Ativo/Inativo</td>
                {role !== "ROLE_ADMIN" ? null : (
                    <>
                <td></td>
                <td></td>
                    </>
                  )}
              </tr>
            </thead>
            <tbody>
              {!checkDevice() ? page.content?.map((employee) => (
                  console.log("Computador"),
                <tr key={employee.idUser}>
                  <td>{employee.fullName}</td>
                  <td>{employee.phone}</td>
                  <td>{employee.pixKey}</td>
                  <td><Tag variant='solid' colorScheme='blue'> {Number.parseFloat(employee.dayOffsAvailable).toFixed(1)} </Tag></td>
                  {role === "ROLE_ADMIN" && <td>R${Number.parseFloat(employee.salary).toFixed(2)}</td>}
                  <td>{String(employee.enabled ? "Ativo" : "Inativo")}</td>
                  {role !== "ROLE_ADMIN" ? null : (
                      <>
                  <td>
                      <ModalEmployees
                        modalTitle={"Editar Colaborador"}
                        buttonText={"Editar"}
                        buttonClassName={"display-modal-button"}
                        id={employee.idUser}
                        edit={true}
                        employer={employee}
                        buttonName={"Salvar"}
                        fetchAllEmployees={fetchAllEmployees}
                      />

                  </td>
                  <td>

                    <ResendEmailButton
                        buttonClassName="text-profile"
                        buttonText={"Reenviar Email"}
                        userEmail={employee.email}
                    />

                  </td>
                      </>
                    )}
                </tr>
              )) : null}
            </tbody>
          </table>
        </div>
        </Box>

        <div className="cards-adjust">
        {checkDevice() ? page.content?.map((employee) => (
            console.log("Celular"),
        <Card maxW='lg' mb={5} bg="white" boxShadow='base' display={{ base: 'flex', md: 'none'}}>
          <CardHeader>
            <Flex spacing='4'>

              <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                <Avatar name={employee.fullName} />
                <Box>
                  <Heading size='sm'>{employee.fullName}</Heading>
                  <Text>Colaborador {String(employee.enabled ? "Ativo" : "Inativo")}</Text>
                </Box>
              </Flex>

            </Flex>

          </CardHeader>
          <CardBody>
            <Text fontSize='lg'>Informações: </Text>
            <Text><Text as="b">email:</Text> {employee.email}</Text>
            <Text><Text as="b">telefone:</Text>  {employee.phone}</Text>
            <Text><Text as="b">pix:</Text>  {employee.pixKey}</Text>

            <ButtonGroup
                size="sm"
                isAttached
                variant="solid"
                colorScheme="blue"
                mt={4}
            >
              <Button>Saldo de day offs:</Button>
              <Button>{Number.parseFloat(employee.dayOffsAvailable).toFixed(1)}</Button>
            </ButtonGroup>

          </CardBody>

          {role !== "ROLE_ADMIN" ? null : (
          <CardFooter
              justify='space-between'
              flexWrap='wrap'
          >
            <ModalEmployees
                flex='1'
                modalTitle={"Editar Colaborador"}
                buttonText={"Editar"}
                buttonClassName={"card-edit-button"}
                id={employee.idUser}
                edit={true}
                employer={employee}
                buttonName={"Salvar"}
                fetchAllEmployees={fetchAllEmployees}
            >
            </ModalEmployees>
            <ResendEmailButton
                buttonClassName="text-profile card-send-email-button"
                buttonText={"Enviar Email"}
                userEmail={employee.email}
            />
          </CardFooter>
          )}

        </Card>
        )) : null}
        </div>
          
        <Pagination page={page} onPageChange={changePage} />
      </div>
    </>
  );
}
