import axios from "axios";

class AuxiliaryService {
    checkCep(cep) {
        console.log(cep);
        return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    }

    checkAge(myBirth) {
        const today = new Date();
        const birthDate = new Date(myBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();

        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    formatarDataParaLocal(data) {
        const cortandoData = data?.replaceAll("-", "/");
        data = cortandoData?.split("/").reverse().join("/");
        return data;
    }

    formatarDataPadraoAmericano(data) {
        const cortandoData = data?.replaceAll("/", "-");
        data = cortandoData?.split("-").reverse().join("-");
        return data
    }

    formatToLocalHour(hour) {
        const getHour = hour?.slice(0, 2)
        const hourH = getHour + "h"
        return hourH
    }

}

export default new AuxiliaryService();
