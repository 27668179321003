import {
    Button, FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import {ModalCloseButton, ModalHeader} from "@chakra-ui/modal";
import {FormControl} from "@chakra-ui/form-control";
import DeleteDayoff from "../services/DeleteDayoff";
import {toast} from "react-toastify";

export default class ModalDeleteDayOff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
        this.handleDelete = this.handleDelete.bind(this)
    }

    handleDelete(e) {
        this.setState({isLoading: true})
        e.preventDefault();
        DeleteDayoff()
            .then((response) => {
                this.props.getEvents();
                toast.success(response.data, {autoClose: 1500});
                this.props.clearStates(e = "deleted")
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => this.setState({isLoading: false}));
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.deleteDayoff}
                    onClose={this.props.clearStates}
                >
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader></ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            <form id="rejectDayOff"
                                  onSubmit={this.handleDelete}>
                                <FormControl>
                                    <FormLabel>Deseja realmente excluir ?</FormLabel>
                                </FormControl>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="modal-cancel-button"
                                mr={2}
                                onClick={this.props.clearStates}>
                                Cancelar
                            </Button>
                            <Button className="modal-submit-button"
                                    type="submit"
                                    form="rejectDayOff"
                                    isLoading={this.state.isLoading}
                            >
                                Excluir
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </div>
        )
    }
}
