import {
    Button, FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import {ModalCloseButton, ModalHeader} from "@chakra-ui/modal";
import {FormControl} from "@chakra-ui/form-control";
import {Textarea} from "@chakra-ui/textarea";
import {updateDayOff} from "../services/DayoffService";
import {toast} from "react-toastify";

export default class ModalDeclineDayOff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
        this.handleDeclineDayOff = this.handleDeclineDayOff.bind(this)
    }

    handleDeclineDayOff(e) {
        this.setState({isLoading: true})
        e.preventDefault();
        let idUserLocalStorage = localStorage.getItem("idUser");
        let eventId = localStorage.getItem("EventId");
        let declineReason = e.target.elements.declineReason.value;
        const dayOffUpdate = {
            idUser: idUserLocalStorage,
            statusDayOff: "REJECTED",
            eventId: eventId,
            reason: declineReason,
        };
        updateDayOff(dayOffUpdate).then((response) => {
            this.props.getEvents();
            toast.success(response.data, {autoClose: 1500});
            this.props.clearStates(e='declined');
        }).finally(() => this.setState({isLoading: false}));
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.declineDayOff}
                    onClose={this.props.clearStates}
                >
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>Rejeitar dayoff</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            <form id="rejectDayOff" onSubmit={this.handleDeclineDayOff}>
                                <FormControl>
                                    <FormLabel>Insira sua justificativa:</FormLabel>
                                    <Textarea name="declineReason" type="text" required/>
                                </FormControl>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="modal-cancel-button"
                                onClick={this.props.clearStates}>
                                Cancelar
                            </Button>

                            <Button
                                className="modal-submit-button"
                                type="submit"
                                form="rejectDayOff"
                                isLoading={this.state.isLoading}
                            >
                                Rejeitar
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </div>
        )
    }
}
