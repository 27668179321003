import axios from 'axios';
import { EVENTS_URL } from '../endpoints/endpoints';
import { api } from './AxiosService';

//Here  I use this function to delete a specific dayoff
export default function DeleteDayoff () {
    let eventId = localStorage.getItem('EventId')
    return api.delete(EVENTS_URL + eventId)
        
}
