import axios from "axios";
import { FORGOT_PASSWORD_URL, LOGIN_URL, RESET_PASSWORD_URL } from "../endpoints/endpoints";

export function userAuthentication(user) {
  return axios.post(LOGIN_URL, user);
}

export function recoverPassword(email) {
  return axios.post(FORGOT_PASSWORD_URL + "?email=" + email);
}

export function changePasswordUsingToken(token, password) {
  return axios.post(RESET_PASSWORD_URL + "?token=" + token, password)
}
export function logoutAndDestroyToken() {
  localStorage.clear();
  window.location.replace(process.env.REACT_APP_LOGOUT_URL);
}
