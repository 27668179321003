export const LOGIN_URL =
  process.env.REACT_APP_PROFILE === "dev"
    ? process.env.REACT_APP_LOCALHOST_URL + process.env.REACT_APP_LOGIN_URL
    : process.env.REACT_APP_CLOUD_URL + process.env.REACT_APP_LOGIN_URL;

export const BASE_URL =
  process.env.REACT_APP_PROFILE === "dev"
    ? process.env.REACT_APP_LOCALHOST_URL
    : process.env.REACT_APP_CLOUD_URL;

export const EVENTS_URL =
  process.env.REACT_APP_PROFILE === "dev"
    ? process.env.REACT_APP_LOCALHOST_URL + process.env.REACT_APP_EVENTS_URL
    : process.env.REACT_APP_CLOUD_URL + process.env.REACT_APP_EVENTS_URL;

export const PAGEABLE_EVENTS_URL =
  process.env.REACT_APP_PROFILE === "dev"
    ? process.env.REACT_APP_LOCALHOST_URL +
      process.env.REACT_APP_PAGEABLE_EVENTS_URL
    : process.env.REACT_APP_CLOUD_URL +
      process.env.REACT_APP_PAGEABLE_EVENTS_URL;

export const USERS_URL =
  process.env.REACT_APP_PROFILE === "dev"
    ? process.env.REACT_APP_LOCALHOST_URL + process.env.REACT_APP_USERS_URL
    : process.env.REACT_APP_CLOUD_URL + process.env.REACT_APP_USERS_URL;

export const FORGOT_PASSWORD_URL =
  process.env.REACT_APP_PROFILE === "dev"
    ? process.env.REACT_APP_LOCALHOST_URL +
      process.env.REACT_APP_RECOVER_PASSWORD_URL
    : process.env.REACT_APP_CLOUD_URL +
      process.env.REACT_APP_RECOVER_PASSWORD_URL;

      export const RESET_PASSWORD_URL =
  process.env.REACT_APP_PROFILE === "dev"
    ? process.env.REACT_APP_LOCALHOST_URL +
      process.env.REACT_APP_RESET_PASSWORD_URL
    : process.env.REACT_APP_CLOUD_URL +
      process.env.REACT_APP_RESET_PASSWORD_URL;

      export const DAYOFF_BY_USERID_URL =
  process.env.REACT_APP_PROFILE === "dev"
    ? process.env.REACT_APP_LOCALHOST_URL +
      process.env.REACT_APP_DAYOFF_BY_USERID_URL
    : process.env.REACT_APP_CLOUD_URL +
      process.env.REACT_APP_DAYOFF_BY_USERID_URL;

      export const USER_PROFILE_URL =
  process.env.REACT_APP_PROFILE === "dev"
    ? process.env.REACT_APP_LOCALHOST_URL +
      process.env.REACT_APP_USER_PROFILE_URL
    : process.env.REACT_APP_CLOUD_URL +
      process.env.REACT_APP_USER_PROFILE_URL;

      export const SEND_FILE_FROM_DAYOFF =
      process.env.REACT_APP_PROFILE === "dev"
        ? process.env.REACT_APP_LOCALHOST_URL +
          process.env.REACT_APP_SEND_FILE_FROM_DAYOFF
        : process.env.REACT_APP_CLOUD_URL +
          process.env.REACT_APP_SEND_FILE_FROM_DAYOFF;

      export const RESEND_EMAIL_URL =
      process.env.REACT_APP_PROFILE === "dev"
        ? process.env.REACT_APP_LOCALHOST_URL +
        process.env.REACT_APP_RESEND_EMAIL_URL
        : process.env.REACT_APP_CLOUD_URL +
        process.env.REACT_APP_RESEND_EMAIL_URL;

        export const REACT_APP_EXPORT_FILE_PDF =
        process.env.REACT_APP_PROFILE === "dev"
          ? process.env.REACT_APP_LOCALHOST_URL +
            process.env.REACT_APP_USERS_URL +
            process.env.REACT_APP_EXPORT_FILE_PDF
          : process.env.REACT_APP_CLOUD_URL +
            process.env.REACT_APP_USERS_URL +
            process.env.REACT_APP_EXPORT_FILE_PDF;

        export const REACT_APP_EXPORT_FILE_EXCEL =
        process.env.REACT_APP_PROFILE === "dev"
          ? process.env.REACT_APP_LOCALHOST_URL +
            process.env.REACT_APP_USERS_URL +
            process.env.REACT_APP_EXPORT_FILE_EXCEL
          : process.env.REACT_APP_CLOUD_URL +
            process.env.REACT_APP_USERS_URL +
            process.env.REACT_APP_EXPORT_FILE_PDF;