import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader, Divider,
    Heading, HStack,
    IconButton, Image,
    Link,
    SimpleGrid, Stack,
    Text, VStack
} from "@chakra-ui/react";
import {FaExternalLinkAlt, FaFacebook, FaGithub, FaInstagram, FaLinkedin} from "react-icons/fa";

export function Footer() {
    const companyName = process.env.REACT_APP_COMPANY_NAME;
    let iniciativa1, iniciativa2, iniciativa3, iniciativa4;
    if (companyName === 'simbiose') {
        iniciativa1 = require(`../assets/${companyName}/imgs/iniciativa1.png`);
        iniciativa2 = require(`../assets/${companyName}/imgs/iniciativa2.png`);
    }
    if (companyName === 'grupomngt') {
        iniciativa1 = require(`../assets/${companyName}/imgs/iniciativa1.png`);
        iniciativa2 = require(`../assets/${companyName}/imgs/iniciativa2.png`);
        iniciativa3 = require(`../assets/${companyName}/imgs/iniciativa3.png`);
        iniciativa4 = require(`../assets/${companyName}/imgs/iniciativa4.png`);
    }


    let data = new Date();
    const currentYear = data.getFullYear()

    return (
        <>
            <div className="footer-1">
                <div className="description-footer">
                    {companyName === 'simbiose' &&
                        <Card variant="unstyled" p={5}>
                            <CardHeader mt={5} alignSelf={{base: 'center', md: 'start'}}>
                                <Heading size='lg'
                                         className="text-color"> {companyName === 'simbiose' ? 'Simbiose Ventures' : 'Grupo MNGT'}</Heading>
                            </CardHeader>
                            <CardBody mt={5}>
                                <Text fontSize='md' className="text-color">
                                    Uma empresa singular de outsourcing, que forma desenvolvedores
                                    com alto padrão técnico e ético. Estamos inseridos no mercado de tecnologia
                                    desde 2012 e já atendemos mais de 80 empresas nacionais e internacionais.
                                </Text>
                            </CardBody>
                        </Card>}

                    <hr/>

                    <SimpleGrid spacing={4} minChildWidth='350px'>

                        {companyName === 'simbiose' &&
                            <Box p={5} justifyContent='center' display='flex'>

                                <VStack w="100%" alignItems={{base: 'center', md: 'start'}}>
                                    <Text className="text-ini text-color" alignSelf="start" mb={7}> INICIATIVAS</Text>

                                    <HStack spacing='40px'>
                                        <Link href='https://brtalents.com/' isExternal>
                                            <Image
                                                w='120px'
                                                h='70px'
                                                objectFit='cover'
                                                src={iniciativa1}
                                                alt='BrTalents'
                                            />
                                        </Link>
                                        <Link href='#Sistema-em-dias' isExternal>
                                            <Image
                                                w='120px'
                                                h='70px'
                                                objectFit='cover'
                                                src={iniciativa2}
                                                alt='Sistema em Dias'
                                            />
                                        </Link>
                                    </HStack>
                                </VStack>

                            </Box>}

                        {companyName === 'grupomngt' &&
                            <Box p={5} justifyContent='center' display='flex'>

                                <VStack w="100%" alignItems={{base: 'center', md: 'start'}}>
                                    <Text className="text-ini text-color" alignSelf="start" mb={7}> INICIATIVAS</Text>

                                    <HStack spacing='40px'>
                                        <Image
                                            w='120px'
                                            h='70px'
                                            objectFit='cover'
                                            src={iniciativa1}
                                            alt='Area Incrivel'
                                        />
                                        <Image
                                            w='100'
                                            h='70px'
                                            objectFit='cover'
                                            src={iniciativa2}
                                            alt='Energia Amiga'
                                        />
                                        <Image
                                            w='120px'
                                            h='70px'
                                            objectFit='cover'
                                            src={iniciativa3}
                                            alt='Lar Incrivel'
                                        />
                                        <Image
                                            w='120px'
                                            h='70px'
                                            objectFit='cover'
                                            src={iniciativa4}
                                            alt='Mais Armazem'
                                        />
                                    </HStack>
                                </VStack>

                            </Box>}


                        <Box justifyContent='flex-end' display='flex' p={5}>
                            <VStack w="100%"
                                    alignItems={{base: 'center', md: 'end'}}
                                    mt={{base: null, md: '75px'}}>
                                    <Box mb={6}>
                                        {companyName === 'simbiose' ?
                                        <Link href='https://www.simbioseventures.com/' isExternal>
                                            <Button leftIcon={<FaExternalLinkAlt/>} className="button">
                                                The Simbiose Way
                                            </Button>
                                        </Link> :
                                            <Link href='https://www.grupomngt.com.br/' isExternal>
                                                <Button leftIcon={<FaExternalLinkAlt/>} className="button">
                                                    Grupo MNGT
                                                </Button>
                                            </Link>}
                                    </Box>

                                <Stack direction='row' spacing={5}>
                                    <Link
                                        href={companyName === 'simbiose' ? 'https://www.facebook.com/simbioseventures' : 'https://www.facebook.com/grupo.mngt'}
                                        isExternal>
                                        <IconButton icon={<FaFacebook/>} className="button" size='lg'/>
                                    </Link>

                                    <Link
                                        href={companyName === 'simbiose' ? 'https://www.linkedin.com/company/simbioseventures/mycompany/' : 'https://www.linkedin.com/company/grupomngt'}
                                        isExternal>
                                        <IconButton icon={<FaLinkedin/>} className="button" size='lg'/>
                                    </Link>
                                    {companyName === 'simbiose' &&
                                        <Link href='https://github.com/s1mbi0se' isExternal>
                                            <IconButton icon={<FaGithub/>} className="button" size='lg'/>
                                        </Link>}

                                    <Link
                                        href={companyName == 'simbiose' ? 'https://www.instagram.com/simbioseventures/' : 'https://www.instagram.com/grupo.mngt?igsh=MzVtano2YnQ3ZjQ='}
                                        isExternal>
                                        <IconButton icon={<FaInstagram/>} className="button" size='lg'/>
                                    </Link>
                                </Stack>

                            </VStack>

                        </Box>

                    </SimpleGrid>

                    <Card variant="unstyled" p={5}>
                        <CardHeader mt={4}>
                            <Text fontSize='md' align="center" className="text-color">
                                {companyName === 'simbiose' ? `© ${currentYear} Simbiose Ventures. Todos os direitos reservados` : `© ${currentYear} Grupo MNGT. Todos os direitos reservados`}
                            </Text>
                        </CardHeader>
                    </Card>

                </div>
            </div>
        </>
    )
}
