import {REACT_APP_EXPORT_FILE_PDF, REACT_APP_EXPORT_FILE_EXCEL} from "../endpoints/endpoints";
import {api} from "./AxiosService";

class ExportFileService {

    exportPdf() {
        return api.get(REACT_APP_EXPORT_FILE_PDF, {
            responseType: 'blob'
        });
    }
    exportExcel() {
        return api.get(REACT_APP_EXPORT_FILE_EXCEL, {
            responseType: 'blob'
        });
    }
}

export default new ExportFileService();