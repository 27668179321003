import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, HStack, ButtonGroup, Button } from '@chakra-ui/react';

// Definindo o componente DayOffTabs
const DayOffTabs = ({ dayoffStatus }) => {
    return (
        <Tabs>
            <TabList>
                <Tab>Simbiose</Tab>
                <Tab>Projeto/Alocação</Tab>
            </TabList>

            <TabPanels>
                <TabPanel>
                    <HStack spacing={6} overflowX="auto">
                        <ButtonGroup
                            size="sm"
                            isAttached
                            variant="solid"
                            colorScheme="blue"
                        >
                            <Button>Dayoffs disponíveis</Button>
                            <Button>{dayoffStatus.daysOffRemmaing}</Button>
                        </ButtonGroup>

                        <ButtonGroup
                            size="sm"
                            isAttached
                            variant="outline"
                            colorScheme="blue"
                        >
                            <Button>Dayoffs tirados</Button>
                            <Button>{dayoffStatus.dayOffRequestds}</Button>
                        </ButtonGroup>

                        <ButtonGroup
                            size="sm"
                            isAttached
                            variant="outline"
                            colorScheme="blue"
                        >
                            <Button>Dias totais trabalhados</Button>
                            <Button>{dayoffStatus.workingDays}</Button>
                        </ButtonGroup>
                    </HStack>
                </TabPanel>
                <TabPanel>
                    <HStack spacing={6} overflowX="auto">
                        <ButtonGroup
                            size="sm"
                            isAttached
                            variant="solid"
                            colorScheme="blue"
                        >
                            <Button>Dayoffs disponíveis</Button>
                            <Button>{dayoffStatus.daysOffRemainingInAllocation}</Button>
                        </ButtonGroup>

                        <ButtonGroup
                            size="sm"
                            isAttached
                            variant="outline"
                            colorScheme="blue"
                        >
                            <Button>Dayoffs tirados</Button>
                            <Button>{dayoffStatus.dayOffRequestsInAllocation}</Button>
                        </ButtonGroup>

                        <ButtonGroup
                            size="sm"
                            isAttached
                            variant="outline"
                            colorScheme="blue"
                        >
                            <Button>Dias totais trabalhados</Button>
                            <Button>{dayoffStatus.workingDaysInAllocation}</Button>
                        </ButtonGroup>
                    </HStack>
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default DayOffTabs;