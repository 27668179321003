import 'react-toastify/dist/ReactToastify.css';

import { ChakraProvider, Spinner } from '@chakra-ui/react'; // Import Spinner aqui
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Profile } from './components/Profile';
import Calendar from './pages/calendar/calendar';
import Index from './pages/employees';
import { Login } from './pages/login/Login';
import { DayOffApproval } from './components/DayOffApproval';
import { ResetPassword } from './components/ResetPassword';
import { CreateInitialPassword } from './components/CreateInitialPassword';

function App() {
    const [isStyleLoaded, setIsStyleLoaded] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    useEffect(() => {
        const companyName = process.env.REACT_APP_COMPANY_NAME;
        import(`./assets/${companyName}/css/style.css`)
            .then(() => {
                setIsStyleLoaded(true);
            })
            .catch(err => {
                console.error('Erro ao carregar CSS APP:', err);
                setLoadingError(true);
            });
    }, []);

    if (isStyleLoaded) {
        return (
            <ChakraProvider>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/employees" element={<Index />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/dayoff-approval" element={<DayOffApproval/>} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/create-password-new-user" element={<CreateInitialPassword/>} />
                </Routes>

                <ToastContainer />
            </ChakraProvider>
        );
    }

}

export default App;
