import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { recoverPassword } from "../services/LoginService";
export function ModalRecoverPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ criteriaMode: "all" });

  const [emailSentSuccess, setEmailSentSuccess] = useState(false);
  const [emailSentError, setEmailSentError] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);

  const recoverEmail = (data) => {
    setEmailSentSuccess(false);
    setEmailSentError(false);
    setisLoading(true);
    recoverPassword(data.email)
      .then((res) => {
        setEmailSentSuccess(true);
        setTimeout(() => onClose(), 10000);
      })
      .catch((err) => {
        setEmailSentError(true);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  return (
    <>
      <Flex height="38px" my={5}>
        <Link
          ml={1}
          onClick={() => {
            setEmailSentSuccess(false);
            setEmailSentError(false);
            reset();
            onOpen();
          }}
          color="#667D9B"
          fontFamily="Source Sans Pro"
          textDecoration="underline"
          fontSize="14px"
          fontWeight="500"
        >
          Esqueci minha senha
        </Link>
      </Flex>
      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Esqueceu a senha?</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text fontSize="15px" color="#00082699">
              Não se preocupe, nós vamos te ajudar! Digite o seu E-mail
              cadastrado para redefinir a sua senha.
            </Text>
            <form id="recoverEmailForm" onSubmit={handleSubmit(recoverEmail)}>
              <FormControl mt={5}>
                <FormLabel className="input-label">E-mail:</FormLabel>
                <Input
                  className="input"
                  focusBorderColor="#637D87"
                  width="600px !important"
                  maxWidth="390px !important"
                  ref={initialRef}
                  placeholder="Digite seu email"
                  _placeholder={{
                    color: "#637D87",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  {...register("email", {
                    required: 'Campo "Email" é obrigatório',
                    pattern: {
                      value: /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(simbioseventures|grupomngt|larincrivel|energiaamiga|maisarmazem|areaincrivel)\.com(\.br)?$/g,
                      message: 'E-mail inválido'
                    }
                  })}
                />
              </FormControl>
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <Text className="error-message" key={type}>
                      {message}
                    </Text>
                  ))
                }
              />
            </form>
            {emailSentError ? (
              <Alert status="error" mt={8}>
                <Flex flexDir="column">
                  <Flex mb={2}>
                    <AlertIcon />
                    <Heading fontSize="20px">Erro ao enviar e-mail!</Heading>
                  </Flex>

                  <Text fontSize="14px">
                    E-mail não existe em nosso banco de dados!
                  </Text>
                </Flex>
              </Alert>
            ) : null}
            {emailSentSuccess ? (
              <Alert status="success" mt={8}>
                <Flex flexDir="column">
                  <Flex mb={2}>
                    <AlertIcon />
                    <Heading fontSize="20px">E-mail enviado!</Heading>
                  </Flex>

                  <Text fontSize="14px">
                    Não esqueça de checar sua caixa de spam ou lixo eletrônico.
                    O e-mail de recuperação pode levar alguns minutos para
                    chegar.
                  </Text>
                </Flex>
              </Alert>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} className="modal-cancel-button">
              Cancelar
            </Button>
            <Button
              className="modal-submit-button"
              colorScheme="blue"
              type="submit"
              form="recoverEmailForm"
              isLoading={isLoading}
            >
              Enviar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
