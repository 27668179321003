import { Icon } from '@chakra-ui/icon';
import { Flex, HStack, Spacer, Text } from '@chakra-ui/layout';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel, Avatar, Box,
  Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader,
  FormControl,
  FormLabel, Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/select';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { VscCircleFilled } from 'react-icons/vsc';
import { toast } from 'react-toastify';

import { PAGEABLE_EVENTS_URL } from '../endpoints/endpoints';
import AuxiliaryService from '../services/AuxiliaryService';
import { api } from '../services/AxiosService';
import { getDayOffByStatus, getDayoffByUserId, updateDayOff } from '../services/DayoffService';
import EmployeesService from '../services/EmployeesService';
import { DayOffApprovalModal } from './DayOffApprovalModal';
import { Header } from './Header';
import Pagination from './Pagination';
import {Footer} from "./Footer";
import {FaPlus} from "react-icons/fa";
import {ModalEmployees} from "./ModalEmployees";
import {ExportTableButtons} from "./ExportTableButtons";
import {ResendEmailButton} from "./ResendEmailButton";

export function DayOffApproval(props) {
  const [page, setPage] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [dayOffs, setDayOffs] = useState([]);
  const [employee, setEmployee] = useState("");
  const [sendingDayoff, setSendingDayoff] = useState(false);
  const [sendingRejectDayoff, setSendingRejectDayoff] = useState(false);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [dayoffId, setDayoffId] = useState("");
  const [idUser, setIdUser] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ criteriaMode: "all" });

  const {
    onOpen: rejectOpen,
    isOpen: rejectIsOpen,
    onClose: rejectOnClose,
  } = useDisclosure();

  const changePage = (number) => {
    setActivePage(number);
  };

  function getUserById() {
    const idUser = localStorage.getItem("idUser");

    EmployeesService.getEmployeeById(idUser).then((response) => {
      setRole(response.data.roles[0].role);
    });
  }

  function fetchUsers() {
    api.get(PAGEABLE_EVENTS_URL + "?status=" + status + "&page=" +activePage+ "&size=25&sortDirection=ASC").then((response) => {
      setPage(response.data);
      setIsLoading(false);
    });
  }

  function getUserIdFromUrl() {
    let url = window.location.href.substring();

    if (!url.includes("id")) {
      return;
    }
    const idUser = url.substring(url.lastIndexOf("=") + 1, url.length);
    fetchDayOffsByUserId(idUser);
  }

  function fetchDayOffsByUserId(idUser) {
    getDayoffByUserId(idUser)
        .then((response) => {
          if (response.data.content.length < 1) {
            onClose();
            return;
          }
          setDayOffs(response.data);
          setEmployee(response.data.content[0].cardTitle);

          if (isOpen) return;
          onOpen();
        })
        .catch();
  }

  function acceptDayoff(dayoff) {
    setSendingDayoff(true);
    const approveDayoff = {
      idUser: dayoff.idUser,
      statusDayOff: "APPROVED",
      eventId: dayoff.eventId,
    };

    updateDayOff(approveDayoff)
        .then((response) => {
          toast.success(response.data);
          fetchDayOffsByUserId(approveDayoff.idUser);
        })
        .catch()
        .finally(() => {
          setSendingDayoff(false);
        });
  }

  const rejectDayoff = (dayoff) => {
    setSendingRejectDayoff(true);
    const rejectDayoff = {
      idUser: idUser,
      statusDayOff: "REJECTED",
      eventId: dayoffId,
      reason: dayoff?.reason,
    };

    updateDayOff(rejectDayoff)
        .then((response) => {
          rejectOnClose()
          toast.success(response.data);
          fetchDayOffsByUserId(rejectDayoff.idUser);
        })
        .catch()
        .finally(() => {
          setSendingRejectDayoff(false);
        });
  };

  useEffect(() => {
    getUserById();
    fetchUsers();
  },[activePage]);

  useEffect(() => {
    getUserIdFromUrl();
  }, []);

  function filter(status) {
    getDayOffByStatus(status, 0).then((response) => {
      setPage(response.data);
    });
  }

  function checkDevice() {
    if ((navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    )) {
      return true
    } else {
      return false
    }
  }

  if (isLoading) {
    return (
        <>
          <Header />

          <div className="description-title-b">
            <div className="description-title">
              <Box align='left'>

                <Skeleton>
                  <Heading as='h3' size='lg' mb={4}>
                    Solicitações de Day Offs
                  </Heading>
                </Skeleton>

              </Box>
              <Skeleton>
                <Select maxW="250px"></Select>
              </Skeleton>
            </div>
          </div>

          <div className="description-box">
            <Skeleton>
              <Box w='100%' h='400px'></Box>
            </Skeleton>
          </div>

        </>
    );
  }

  return (
      <>
        <Modal isOpen={rejectIsOpen} onClose={rejectOnClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Rejeitar dayoff</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form id="reject-dayoff" onSubmit={handleSubmit(rejectDayoff)}>
                <FormControl>
                  <FormLabel className="input-label">
                    Insira sua justificativa de rejeição:
                  </FormLabel>
                  <Textarea
                      type="text"
                      className="input-profile"
                      width="100% !important"
                      {...register("reason", {required: "Campo obrigatório"})}
                  />
                </FormControl>
                <ErrorMessage
                    errors={errors}
                    name="reason"
                    render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <Text className="error-message" key={type}>
                              {message}
                            </Text>
                        ))
                    }
                />
              </form>
            </ModalBody>
            <ModalFooter>
              <Button className="modal-cancel-button">Cancelar</Button>
              <Button
                  isLoading={sendingRejectDayoff}
                  form="reject-dayoff"
                  type="submit"
                  className="modal-submit-button"
              >
                Rejeitar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Dayoffs pendentes de {employee}</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Accordion allowToggle>
                {dayOffs.content?.map((dayoff) => (
                    <AccordionItem>
                      <AccordionButton>
                        <Flex textAlign="left" alignItems="center">
                          <Icon color="orange" as={VscCircleFilled} />
                          {AuxiliaryService.formatarDataParaLocal(dayoff.date)}
                        </Flex>
                        <Spacer />
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        <Flex>
                          <FormControl>
                            <FormLabel className="input-label">
                              Tipo do Dayoff
                            </FormLabel>
                            <Input
                                type="text"
                                className="input-profile"
                                defaultValue={dayoff.typeDay}
                                disabled
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel className="input-label">
                              Justificativa
                            </FormLabel>
                            <Textarea
                                type="text"
                                className="input-profile"
                                defaultValue={dayoff.justification}
                                disabled
                            />
                          </FormControl>
                          <HStack align="end">
                            <Button
                                className="modal-cancel-button"
                                onClick={() => {
                                  setIdUser(dayoff.idUser);
                                  setDayoffId(dayoff.eventId);
                                  rejectOpen();
                                }}
                            >
                              Rejeitar
                            </Button>
                            <Button
                                className="modal-submit-button"
                                isLoading={sendingDayoff}
                                onClick={() => {
                                  acceptDayoff(dayoff);
                                }}
                            >
                              Aprovar
                            </Button>
                          </HStack>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                ))}
              </Accordion>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Header />

        <div className="description-title-b">
          <div className="description-title">
            <Box align='left' w="100%">
              <Heading as='h3' size='lg' mb={4} className="text-color">
                Solicitações de Day Offs
              </Heading>
            </Box>
            <Select
                maxW="250px"
                bg="white"
                borderColor='white'
                onChange={(e) => {
                  setStatus(e.target.value);
                  filter(e.target.value);
                }}
            >
              <option value="PENDING,EMERGENCY">Pendente</option>
              <option value="APPROVED">Aprovado</option>
              <option value="REJECTED">Rejeitado</option>
              <option value="JUSTIFIED">Justificado</option>
            </Select>
          </div>
        </div>

        <div className="description-box">

          <Box display={{ base: 'none', md: 'flex'}}>
            <div className="table-responsivo">
              <table>
                <thead>
                <tr>
                  <td>Colaborador</td>
                  <td>Data</td>
                  <td>Status</td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                {!checkDevice() ? page.content?.map((dayOff) => (
                    <>
                      <tr key={dayOff.id}>
                        <td>{dayOff.cardTitle}</td>
                        <td>
                          {AuxiliaryService.formatarDataParaLocal(dayOff.date)}
                        </td>
                        <td>
                          {dayOff.status === "PENDING" ? (
                              <>
                                <Flex alignItems="center">
                                  <Icon color="orange" as={VscCircleFilled} />
                                  <Text>Pendente</Text>
                                </Flex>
                              </>
                          ) : dayOff.status === "REJECTED" ? (
                              <>
                                <Flex alignItems="center">
                                  <Icon color="#a70000" as={VscCircleFilled} />
                                  <Text>Rejeitado</Text>
                                </Flex>
                              </>
                          ) : dayOff.status === "EMERGENCY" ? (
                                  <>
                                    <Flex alignItems="center">
                                      <Icon color="#8A2BE2" as={VscCircleFilled} />
                                      <Text>Emergêncial</Text>
                                    </Flex>
                                  </>
                          ) : dayOff.status === "JUSTIFIED" ? (
                                  <>
                                    <Flex alignItems="center">
                                      <Icon color="#15803d" as={VscCircleFilled} />
                                      <Text>Justificado</Text>
                                    </Flex>
                                  </>
                          ) : (
                              <>
                                <Flex alignItems="center">
                                  <Icon color="#3388FF" as={VscCircleFilled} />
                                  <Text>Aprovado</Text>
                                </Flex>
                              </>
                          )}
                        </td>
                        <td>
                          {role !== "ROLE_ADMIN" ? null : (
                              <DayOffApprovalModal
                                  dayOff={dayOff}
                                  fetchUsers={filter}
                                  status={status}
                                  buttonClassName={"display-modal-button"}
                              />
                          )}
                        </td>
                      </tr>
                    </>
                )) : null}
                </tbody>
              </table>
            </div>
          </Box>

          <div className="cards-adjust">
            {checkDevice() ? page.content?.map((dayOff) => (
                console.log("Celular"),
                    <Card maxW='lg' mb={5} bg="white" boxShadow='base' display={{ base: 'flex', md: 'none'}} key={dayOff.id}>
                      <CardHeader>
                        <Flex spacing='4'>

                          <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                            <Avatar name={dayOff.cardTitle} />
                            <Box>
                              <Heading size='sm'>{dayOff.cardTitle}</Heading>
                              <Flex>
                                <Text>Status</Text>
                                {dayOff.status === "PENDING" ? (
                                    <>
                                      <Flex alignItems="center">
                                        <Icon color="orange" as={VscCircleFilled} />
                                        <Text>Pendente</Text>
                                      </Flex>
                                    </>
                                ) : dayOff.status === "REJECTED" ? (
                                    <>
                                      <Flex alignItems="center">
                                        <Icon color="#a70000" as={VscCircleFilled} />
                                        <Text>Rejeitado</Text>
                                      </Flex>
                                    </>
                                ) : dayOff.status === "EMERGENCY" ? (
                                    <>
                                      <Flex alignItems="center">
                                        <Icon color="#8A2BE2" as={VscCircleFilled} />
                                        <Text>Emergêncial</Text>
                                      </Flex>
                                    </>
                                ) : dayOff.status === "JUSTIFIED" ? (
                                    <>
                                      <Flex alignItems="center">
                                        <Icon color="#15803d" as={VscCircleFilled} />
                                        <Text>Justificado</Text>
                                      </Flex>
                                    </>
                                ) : (
                                    <>
                                      <Flex alignItems="center">
                                        <Icon color="#3388FF" as={VscCircleFilled} />
                                        <Text>Aprovado</Text>
                                      </Flex>
                                    </>
                                )}
                              </Flex>

                            </Box>
                          </Flex>

                        </Flex>

                      </CardHeader>
                      <CardBody>
                        <Text fontSize='sm' as="b">
                          Solicita {String(dayOff.typeDay === "HALF_DAYOFF" ? "MEIO DAYOFF" : "DAYOFF")} para:

                        </Text>
                        <Text fontSize='lg'>
                          {AuxiliaryService.formatarDataParaLocal(dayOff.date)}&nbsp;
                          {dayOff.typeDay === "HALF_DAYOFF" ? (
                              <>
                                das {AuxiliaryService.formatToLocalHour(dayOff.startTime)} às {AuxiliaryService.formatToLocalHour(dayOff.endTime)}
                              </>
                          ) : null}

                        </Text>

                      </CardBody>

                      <CardFooter
                          justify='space-between'
                          flexWrap='wrap'
                      >
                        <DayOffApprovalModal
                            dayOff={dayOff}
                            fetchUsers={filter}
                            status={status}
                            buttonClassName={"card-edit-button"}
                        />
                      </CardFooter>

                    </Card>
            )) : null}
          </div>

          <Pagination page={page} onPageChange={changePage} />

        </div>

        <Footer/>
      </>
  );
}
