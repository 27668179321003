import React, {useEffect, useState} from "react";
import {Button, FormControl, FormLabel, Input, SimpleGrid, Stack, Text} from "@chakra-ui/react";
import {ErrorMessage} from "@hookform/error-message";
import { useForm } from 'react-hook-form';
import EmployeesService from "../services/EmployeesService";
import {toast} from "react-toastify";

export function NewPassword() {

    const [user, setUser] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [idUser, setIdUser] = useState("");


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ criteriaMode: "all" });

    useEffect(() => {
        const idUser = localStorage.getItem("idUser")
        setIdUser(idUser)
        EmployeesService.getEmployeeById(idUser).then(res => {
            setUser(res.data)
            setIsLoading(false)
        })
    }, [])

    const updatePassword = password => {
        setIsButtonLoading(true);
        EmployeesService.updatePassword(password, idUser).then(res => {
            toast.success(res.data);
            reset()
        }).catch((error) => {
            toast.error(error.response.data);
        }).finally(() => {
            setIsButtonLoading(false);
        })
    }

    return(
        <>
            <form id="newPasswordForm" onSubmit={handleSubmit(updatePassword)}>
                <SimpleGrid
                    columns={['1', '1', '2', '3']}
                    spacingY="20px"
                    spacingX="20px"
                    max-width="1000px"
                    margin="auto"
                    padding="0px"
                >

                    <FormControl>
                        <FormLabel className="input-label">Senha Atual:</FormLabel>
                        <Input
                            className="input-profile"
                            type="password"
                            id="currentPassword"
                            {...register("currentPassword", {
                                minLength: {value:6, message: "A senha precisa ter no mínimo 6 caracteres"}
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="currentPassword"
                            render={({ messages }) =>
                                messages &&
                                Object.entries(messages).map(([type, message]) => (
                                    <Text
                                        fontSize="14px"
                                        color="rgb(211, 66, 66)"
                                        key={type}
                                    >
                                        {message}
                                    </Text>
                                ))
                            }
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel className="input-label">Nova senha:</FormLabel>
                        <Input
                            className="input-profile"
                            type="password"
                            id="password"
                            {...register("password", {
                                minLength: {value:6, message: "A senha precisa ter no mínimo 6 caracteres"}
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="password"
                            render={({ messages }) =>
                                messages &&
                                Object.entries(messages).map(([type, message]) => (
                                    <Text
                                        fontSize="14px"
                                        color="rgb(211, 66, 66)"
                                        key={type}
                                    >
                                        {message}
                                    </Text>
                                ))
                            }
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel className="input-label">Confirmar nova senha:</FormLabel>
                        <Input
                            className="input-profile"
                            type="password"
                            id="passwordConfirm"
                            {...register("passwordConfirm", {
                                minLength: {value:6, message: "A senha precisa ter no mínimo 6 caracteres"}
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="passwordConfirm"
                            render={({ messages }) =>
                                messages &&
                                Object.entries(messages).map(([type, message]) => (
                                    <Text
                                        fontSize="14px"
                                        color="rgb(211, 66, 66)"
                                        key={type}
                                    >
                                        {message}
                                    </Text>
                                ))
                            }
                        />
                    </FormControl>
                </SimpleGrid>
            </form>

            <Stack direction='row' float="right" mt={9}>
                <Button className="button"
                        type="submit"
                        form="newPasswordForm"
                        isLoading={isButtonLoading}
                        loadingText='Salvando'>
                    Salvar senha
                </Button>
            </Stack>

        </>
    )
}

