import {SEND_FILE_FROM_DAYOFF, USERS_URL, USER_PROFILE_URL, RESEND_EMAIL_URL} from "../endpoints/endpoints";
import { api } from "./AxiosService";

class EmployeesService {
  async getAllEmployees(activePage) {
    const getAllEmployees = await api.get(
      `${USERS_URL}?page=${activePage}&size=25`
    );
    return getAllEmployees;
  }

  async getAllEmployeesAtOnce() {
    const getAllEmployees = await api.get(
        `${USERS_URL}?page=0&size=500`
    );
    return getAllEmployees;
  }
  createEmployee(employee) {
    return api.post(USERS_URL, employee);
  }
  updateEmployee(employeeId, employee) {
    return api.patch(USERS_URL + "/" + employeeId, employee);
  }
  adminUpdateEmployee(employeeId, employee) {
    return api.patch(USERS_URL + "/admin/" + employeeId, employee);
  }
  getEmployeeById(employeeId) {
    return api.get(USERS_URL + "/" + employeeId);
  }

  searchEmployee(query) {
    return api.get(`${USERS_URL}?q=${query}`);
  }

  dayoffStatus(idUser) {
    return api.get(`${USERS_URL}/${idUser}/dayoff-status`)
  }

  updatePassword(password, idUser) {
    return api.patch(`${USERS_URL}/new-password?idUser=${idUser}`, password)
  }

  getProfilePicture() {
    return api.get(USER_PROFILE_URL);
  }

  uploadProfilePicture(image, isProfilePicture) {
    return api.post(SEND_FILE_FROM_DAYOFF + "?isProfilePicture=" +isProfilePicture, image)
  }

  resendEmail(email) {
    return api.post(RESEND_EMAIL_URL + "?email=" + email)
  }

}

export default new EmployeesService();
