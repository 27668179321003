import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Icon,
  Avatar,
  AvatarBadge,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineUpload } from "react-icons/ai";
import { HiOutlinePhotograph } from "react-icons/hi";
import { AiOutlineCamera } from "react-icons/ai";
import EmployeesService from "../services/EmployeesService";
import { upload } from "@testing-library/user-event/dist/upload";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";

export function UploadProfilePicture() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fileName, setFilename] = useState("");
  const [displayProfileIcon, setDisplayProfileIcon] = useState(false);
  const [isButtonBlocked, setIsButtonBlocked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [imgErrorMsg, setImgErrorMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ criteriaMode: "all" });

  const uploadProfilePicture = (image) => {

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", image.file[0]);

    EmployeesService.uploadProfilePicture(formData, true)
      .then(() => {
        localStorage.removeItem("userProfilePicture")
        onClose();
        window.location.reload()
      })
      .catch(error => {
        setImgError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function openFileUpload() {
    const openFile = document.getElementById("upload-file");
    openFile.click();
  }

  function handleFileUploadChange(e) {

    if (e.length === 0) {
      setDisplayProfileIcon(false);
      setIsButtonBlocked(true);
      return;
    }
    const lastIndex = e.lastIndexOf("\\");
    const fileName = e.substring(lastIndex + 1, e.length);
    setFilename(fileName);
    setIsButtonBlocked(false);
    setDisplayProfileIcon(true);
  }

  return (
    <>
      <AvatarBadge
        as={AiOutlineCamera}
        className="profile-icon"
        onClick={() => {
          onOpen();
          setIsButtonBlocked(true);
          setFilename("");
          setDisplayProfileIcon(false);
        }}
      />

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Definir foto do perfil</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text mb={5}>
              {" "}
            </Text>
            <form
              id="send-profile-picture"
              onSubmit={handleSubmit(uploadProfilePicture)}
            >
              <Input
                id="upload-file"
                type="file"
                accept=".jpeg, .png, .jpg"
                display="none"
                {...register("file", {
                  required: true,
                  onChange: (e) => handleFileUploadChange(e.target.value),
                })}
              />
            </form>
            
            <Button
              rightIcon={<AiOutlineUpload />}
              className="button button-upload "
              onClick={() => {
                openFileUpload();
              }}
            >
              Escolher foto
            </Button>
            {displayProfileIcon ? (
              <>
                <Flex mt={1} alignItems="center">
                  <Icon mr={1} as={HiOutlinePhotograph} />
                  {fileName}
                </Flex>
              </>
            ) : null}
            <ErrorMessage
                      errors={errors}
                      name="file"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        ))
                      }
                    />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              Cancel
            </Button>
            <Button
              type="submit"
              form="send-profile-picture"
              className="button"
              isDisabled={isButtonBlocked}
              isLoading={isLoading}
            >
              Enviar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
