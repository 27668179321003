import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {AiFillCheckCircle, AiOutlineCheck, AiOutlineCheckCircle} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { changePasswordUsingToken } from "../services/LoginService";

export function ResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ criteriaMode: "all" });

  const [isLoading, setIsLoading] = useState(false);
  const password = useRef({});
  password.current = watch("password", "");
  const navigate = useNavigate();
  function getTokenOnUrl() {
    const regexExp =
      /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
    let token = window.location.href.substring();
    const uuid = token.substring(token.lastIndexOf("=") + 1, token.length);

    if (!regexExp.test(uuid)) {
      navigate("../");
      return;
    }

    return uuid;
  }

  const submitPassword = (password) => {
    setIsLoading(true);
    changePasswordUsingToken(getTokenOnUrl(), password)
      .then(() => {
        toast.success(
          "Senha redefinida com sucesso! Você será redirecionado para a página inicial em 5 segundos."
        );
        setTimeout(() => navigate("../"), 5000);
      })
      .catch(() => {
        toast.error(
          "Token inválido, solicite um novo token para prosseguir com a alteração de senha!"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTokenOnUrl();
  });

  const companyName = process.env.REACT_APP_COMPANY_NAME;
  const greenCheck = require(`../assets/${companyName}/imgs/green-check.png`);
  const secondaryLogo = require(`../assets/${companyName}/imgs/secondary-logo.png`);
  const principalLogo = require(`../assets/${companyName}/imgs/principal-logo.png`);

  return (
    <>
      <Flex className="recover-background">
        <Flex className="container-fullscreen">
          <Flex
              className="modal-logo-recover"
          >
            <Image
                className="imgLogo"
                src={secondaryLogo}
                alt="simbiose"
                boxSize="100px, 50px"
            />

            <Image
                src={principalLogo}
                alt="simbiose"
                boxSize="100px, 50px"
            />
          </Flex>
          <Flex
              className="modal-recover"
          >
            <VStack align="left" color="#284D74">
              <Button
                  leftIcon={<ChevronLeftIcon />}
                  width="fit-content"
                  variant="link"
                  color="#284D74"
                  position="absolute"
                  margin="-50px"
                  mb={2}
                  onClick={() => navigate("/")}
              >
                Voltar
              </Button>
              <Heading
                  fontSize="24px"
                  mb="5px !important"
              >
                Criar nova senha
              </Heading>
              <Text
                  color="#284D74 !important"
              >
                Cadastre uma nova senha para acessar sua conta.
              </Text>
              <Text
                  className="recover-text-down"
              >Para uma senha segura:</Text>

              <HStack
                  className="text-recover"
              >
                <Image
                    src={greenCheck}
                    alt="icon"
                    boxSize="15px"
                />
                <Text
                    marginLeft="4px !important"
                >Mais de 6 caracteres;</Text>
              </HStack>

              <HStack
                  className="text-recover"
              >
                <Image
                    src={greenCheck}
                    alt="icon"
                    boxSize="15px"
                />
                <Text
                    marginLeft="4px !important"
                >Não utilize nome, sobrenome ou CPF;</Text>
              </HStack>

              <HStack
                  className="text-recover"
              >
                <Image
                    src={greenCheck}
                    alt="icon"
                    boxSize="15px"
                />
                <Text
                    marginLeft="4px !important"
                >
                  Não utilize letras ou números sequenciais ou repetidos;
                </Text>
              </HStack>
              <Text mt="8px !important" mb="8px !important">
                Para prosseguir, defina uma nova senha.
              </Text>
            </VStack>

            <form id="resetPassword" onSubmit={handleSubmit(submitPassword)}>
              <FormControl>
                <Input
                    id="inputRecover"
                    className="input"
                    border="none !important"
                    marginTop="20px !important"
                    marginBottom="12px !important"
                    backgroundColor="#FFFFFF"
                    type="password"
                    placeholder="Digite sua nova senha"
                    _placeholder={{
                      color: "#637D87",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    {...register("password", {
                      required: "Campo obrigatório",
                      minLength: {
                        value: 6,
                        message: "A senha deve conter no mínimo 6 caracteres",
                      },
                    })}
                />
              </FormControl>
              <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                          <Text className="error-message" key={type}>
                            {message}
                          </Text>
                      ))
                  }
              />

              <FormControl mt={1}>
                <Input
                    id="inputRecover"
                    className="input"
                    border="none !important"
                    backgroundColor="#FFFFFF"
                    type="password"
                    placeholder="Repita a nova senha"
                    _placeholder={{
                      color: "#637D87",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    {...register("passwordConfirm", {
                      required: "Campo obrigatório",
                      validate: (value) => {
                        if (watch("password") !== value) {
                          return "As senhas não coincidem!";
                        }
                      },
                    })}
                />
              </FormControl>
              <ErrorMessage
                  errors={errors}
                  name="passwordConfirm"
                  render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                          <Text className="error-message" key={type}>
                            {message}
                          </Text>
                      ))
                  }
              />
            </form>
            <Flex mt={5}>
              <Button
                  className="button"
                  isLoading={isLoading}
                  width="full"
                  type="submit"
                  form="resetPassword"
              >
                Criar nova senha
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
