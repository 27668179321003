import "moment-timezone";

import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spinner,
    Switch,
    Textarea,
    Tooltip,
} from "@chakra-ui/react";
import React from "react";
import InputMask from "react-input-mask";

import {formatation} from "../pages/calendar/formatDate";
import CreateDayOff from "../services/CreatDayOff";
import UpDateDayOff from "../services/UpDateDayOff";
import {toast} from "react-toastify";
import {updateDayOff} from "../services/DayoffService";
import {type} from "@testing-library/user-event/dist/type";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import {Text} from "@chakra-ui/layout";

export default class ModalDayOff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, isLoadingApprove: false, isLoadingJustify: false, sickNoteSwitch: false, emergency: false,
        };
        this.criarDayOff = this.criarDayOff.bind(this);
        this.atualizarDayoff = this.atualizarDayoff.bind(this);
        this.handleApproveDayOff = this.handleApproveDayOff.bind(this);
        this.handleJustifyDayOff = this.handleJustifyDayOff.bind(this);
        this.clearLocalStates = this.clearLocalStates.bind(this)
    }

    clearLocalStates() {
        this.setState({sickNoteSwitch: false})
        this.props.clearStates()
    }

    criarDayOff(e) {
        e.preventDefault();
        const formData = new FormData();
        const arquivo = e.target.elements.fileUpload === undefined ? undefined : e.target.elements.fileUpload.files[0];
        this.setState({isLoading: true});
        const IdUser = localStorage.getItem("idUser");
        const usr = e.target.elements.employee?.value;
        let defaultEndDate = "00-00-0000";
        let endDate = e.target.elements.dayOffEndDate === undefined ? defaultEndDate : e.target.elements.dayOffEndDate.value;
        let date = e.target.elements.dayOffDate.value;
        let dateFim = formatation(date, endDate);
        let defaultHour = "00:00:00";
        let startHour = e.target.elements.dayOffStartHour === undefined ? defaultHour : e.target.elements.dayOffStartHour.value;
        const dayOff = {
            idUser: this.props.role === "ROLE_ADMIN" ? usr : IdUser,
            typeDay: e.target.elements.dayOffTypee.value,
            date: dateFim,
            startTime: startHour,
            justification: e.target.elements.dayOffJustification.value,
            file: arquivo,
            // status: "non",
            emergency: e.target.elements.emergency.checked,
        };

        if (!arquivo) {
            formData.append("arquivo", new Blob(["teste"], {type: "text-plain"}));
        } else {
            formData.append("arquivo", arquivo);
        }

        formData.append("eventsDto", new Blob([JSON.stringify(dayOff)], {
            type: "application/json",
        }));

        CreateDayOff(formData)
            .then((response) => {
                this.props.getEvents();
                toast.success(response.data, {autoClose: 1500});
                this.clearLocalStates()
            })
            .catch((error) => toast.error(error.response.data))
            .finally(() => this.setState({isLoading: false}));
    }

    atualizarDayoff(e) {
        this.setState({isLoading: true});
        e.preventDefault();
        const formData = new FormData();
        const arquivo = e.target.elements.fileUpload === undefined ? undefined : e.target.elements.fileUpload.files[0];
        let date = e.target.elements.dayOffDate.value;
        let defaultEndDate = "00-00-0000";
        let endDate = e.target.elements.dayOffEndDate === undefined ? defaultEndDate : e.target.elements.dayOffEndDate.value;
        let dateFim = formatation(date, endDate);
        let defaultHour = "00:00:00";
        let startHour = e.target.elements.dayOffStartHour === undefined ? defaultHour : e.target.elements.dayOffStartHour.value;

        const dayOff = {
            status: "PENDING",
            typeDay: e.target.elements.dayOffTypee.value,
            date: dateFim,
            startTime: startHour,
            justification: e.target.elements.dayOffJustification.value,
            emergency: e.target.elements.emergency.checked,
        };

        if (!arquivo) {
            formData.append("arquivo", new Blob(["teste"], {type: "text-plain"}));
        } else {
            formData.append("arquivo", arquivo);
        }

        formData.append("updateEventsDto", new Blob([JSON.stringify(dayOff)], {
            type: "application/json",
        }));
        const eventId = localStorage.getItem("EventId")
        UpDateDayOff(eventId, formData)
            .then((response) => {
                this.props.getEvents();
                toast.success(response.data, {autoClose: 1500});
                this.clearLocalStates()
            })
            .catch((error) => toast.error(error.response.data))
            .finally(() => this.setState({isLoading: false}));
    }

    handleApproveDayOff() {
        this.setState({isLoadingApprove: true});
        let idUserLocalStorage = localStorage.getItem("idUser");
        let eventId = localStorage.getItem("EventId");
        const dayOffUpdate = {
            idUser: idUserLocalStorage, statusDayOff: "APPROVED", eventId: eventId,
        };
        updateDayOff(dayOffUpdate)
            .then((response) => {
                this.props.getEvents();
                toast.success(response.data, {autoClose: 1500});
                this.clearLocalStates()
            })
            .finally(() => this.setState({isLoadingApprove: false}));
    }

    handleJustifyDayOff() {
        this.setState({isLoadingJustify: true});
        let idUserLocalStorage = localStorage.getItem("idUser");
        let eventId = localStorage.getItem("EventId");
        const dayOffUpdate = {
            idUser: idUserLocalStorage, statusDayOff: "JUSTIFIED", eventId: eventId,
        };
        updateDayOff(dayOffUpdate)
            .then((response) => {
                this.props.getEvents();
                toast.success(response.data, {autoClose: 1500});
                this.clearLocalStates()
            })
            .finally(() => this.setState({isLoadingJustify: false}));
    }

    render() {
        return (<div>
                <Modal isOpen={this.props.show} onClose={this.clearLocalStates}>
                    <ModalOverlay/>
                    <ModalContent>
                        {this.props.keyss === "ADMIN" || (this.props.keyss === "USER" &&
                            this.props.dayOffStatus !== "APPROVED") ? (
                                <ModalHeader>Atualizar Dayoff</ModalHeader>) :
                            this.props.keyss === "onlyView" ||
                            (this.props.keyss === "USER" && this.props.dayOffStatus === "APPROVED") ||
                            this.props.keyss === " ADMIN" ?
                                (<ModalHeader>Detalhes Dayoff</ModalHeader>) : (
                                    <ModalHeader>Cadastrar Dayoff</ModalHeader>)}
                        <ModalCloseButton/>
                        {this.props.isLoadingData ? (<ModalBody>
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                height="229px"
                                flexDir="column"
                            >
                                <Spinner size="lg"/>
                                <Text mt={2}>Carregando dados...</Text>
                            </Flex>
                        </ModalBody>) : (<>
                                <ModalBody>
                                    <Flex flexDir="column">
                                        <form
                                            enctype="multipart/form-data"
                                            onSubmit={this.props.keyss === "USER" || this.props.keyss === "ADMIN" ?
                                                this.atualizarDayoff : this.criarDayOff}
                                            id="CreateDayOff"
                                        >
                                            {this.props.role === "ROLE_ADMIN" &&
                                                <FormControl>
                                                    <FormLabel
                                                        className="input-label"
                                                    >
                                                        Colaborador
                                                    </FormLabel>
                                                    <Select
                                                        name="employee"
                                                        mb={4}
                                                        disabled={this.props.role !== "ROLE_ADMIN" ||
                                                            this.props.keyss === "ADMIN"}
                                                        hidden={this.props.role !== "ROLE_ADMIN"}
                                                        className="modal-input"
                                                    >
                                                        {this.props.employees.map((employee) => (
                                                            <option value={employee.idUser}>
                                                                {employee.fullName}
                                                            </option>))}
                                                    </Select>
                                                </FormControl>
                                            }
                                            <FormControl>
                                                <FormLabel className="input-label">
                                                    Tipo do Dayoff
                                                </FormLabel>
                                                <Select
                                                    name="dayOffTypee"
                                                    value={this.props.typeDay}
                                                    onChange={this.props.handleChange}
                                                    disabled={this.props.keyss === "onlyView" ||
                                                        (this.props.keyss === "USER" &&
                                                            this.props.dayOffStatus === "APPROVED")}
                                                    className="modal-input"
                                                >
                                                    <option value="DAYOFF">Dayoff</option>
                                                    <option value="HALF_DAYOFF">Meio Dayoff</option>
                                                </Select>
                                            </FormControl>
                                            {this.props.typeDay === "HALF_DAYOFF" && (<FormControl mt={4}>
                                                <FormLabel className="input-label">Início</FormLabel>
                                                <Input
                                                    name="dayOffStartHour"
                                                    type="time"
                                                    min="07:00:00"
                                                    max="16:00:00"
                                                    step="1"
                                                    placeholder="Início"
                                                    defaultValue={this.props.dayOffStartHour}
                                                    disabled={this.props.keyss === "onlyView" ||
                                                        (this.props.keyss === "USER" &&
                                                            this.props.dayOffStatus === "APPROVED")}
                                                    className="modal-input"
                                                    required
                                                />
                                            </FormControl>)}
                                            <FormControl mt={4}>
                                                <FormLabel className="input-label">Data Inicio</FormLabel>
                                                <Input
                                                    name="dayOffDate"
                                                    as={InputMask}
                                                    mask={this.props.keyss === "USER" ? "99-99-9999" : "99-99-9999/99-99-9999"}
                                                    maskChar={null}
                                                    defaultValue={this.props.dateSelected}
                                                    disabled={this.props.keyss === "onlyView" ||
                                                        (this.props.keyss === "USER" &&
                                                            this.props.dayOffStatus === "APPROVED")}
                                                    className="modal-input"
                                                    required
                                                />
                                                <FormControl mt={3} mb={3}>
                                                    <Flex alignItems="center">
                                                        <FormLabel
                                                            htmlFor="email-alerts"
                                                            hidden={this.props.keyss === "USER" ||
                                                                this.props.keyss === "onlyView" ||
                                                                this.props.keyss === "ADMIN" ||
                                                                this.props.rangeSelected === true}
                                                            className="input-label"
                                                        >
                                                            Dayoff recorrente
                                                        </FormLabel>
                                                        <Switch
                                                            id="email-alerts"
                                                            onChange={this.props.handleChangeSwitch}
                                                            hidden={this.props.keyss === "USER" ||
                                                                this.props.keyss === "onlyView" ||
                                                                this.props.keyss === "ADMIN" ||
                                                                this.props.rangeSelected === true}
                                                        />
                                                    </Flex>
                                                </FormControl>
                                                {this.props.switch === true && (<FormControl mt={4} mr={4} mb={4}>
                                                    <FormLabel className="input-label">Data fim</FormLabel>
                                                    <Input
                                                        name="dayOffEndDate"
                                                        as={InputMask}
                                                        mask={"99-99-9999"}
                                                        maskChar={null}
                                                        required
                                                    />
                                                </FormControl>)}
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel className="input-label">Justificativa</FormLabel>
                                                <Textarea
                                                    name="dayOffJustification"
                                                    type="text"
                                                    defaultValue={this.props.dayOffJustification}
                                                    disabled={this.props.keyss === "onlyView" ||
                                                        (this.props.keyss === "USER" &&
                                                            this.props.dayOffStatus === "APPROVED")}
                                                    required
                                                />
                                            </FormControl>
                                            <FormControl mt={3} mb={3}>
                                                <Flex alignItems="center">
                                                    <FormLabel htmlFor="emergency-switch" className="input-label">
                                                        Emergência
                                                    </FormLabel>
                                                    <Switch
                                                        id="emergency-switch"
                                                        name="emergency"
                                                        isChecked={this.props.emergency || false}
                                                        onChange={(e) => this.props.handleEmergencyChange(e.target.checked)}
                                                        disabled={this.props.keyss === "onlyView" ||
                                                            (this.props.keyss === "USER" && this.props.dayOffStatus === "APPROVED")}
                                                    />
                                                    <Tooltip
                                                        label="Para emergências médicas, familiares ou climáticas. Sujeito a revisão administrativa."
                                                        fontSize="sm"
                                                        placement="top"
                                                    >
                                                        <Text as="span" ml={2} fontSize="sm" color="gray.600" cursor="pointer">
                                                            ⓘ
                                                        </Text>
                                                    </Tooltip>
                                                </Flex>
                                            </FormControl>

                                            {this.props.sickNoteLink !== "" && <Alert status="warning" mt={5}>
                                                <Flex flexDir="column">
                                                    <Flex>
                                                        <AlertIcon/>

                                                        <AlertDescription>
                                                            Este dayoff possui documento anexado, clique no
                                                            botão abaixo para visualizar.
                                                        </AlertDescription>
                                                    </Flex>
                                                    <Flex justifyContent="center">
                                                        <Button
                                                            className="button"
                                                            mt={2}
                                                            rightIcon={<ExternalLinkIcon/>}
                                                            onClick={() => {
                                                                window.open(this.props.sickNoteLink)
                                                            }}
                                                        >Visualizar documento</Button>
                                                    </Flex>
                                                </Flex>
                                            </Alert>}
                                            {this.props.keyss === "onlyView" ||
                                            (this.props.keyss === "USER" &&
                                                this.props.dayOffStatus === "APPROVED") ?
                                                null :
                                                <FormControl mt={3} mb={3}>
                                                    <Flex alignItems="center">
                                                        <FormLabel
                                                            htmlFor="email-alerts"
                                                            className="input-label"
                                                        >
                                                            {this.props.sickNoteLink !== "" ?
                                                                "Deseja reenviar o atestado?" :
                                                                "Deseja enviar atestado?"
                                                            }
                                                        </FormLabel>
                                                        <Switch
                                                            id="email-alerts"
                                                            onChange={() => {
                                                                this.state.sickNoteSwitch === false ?
                                                                    this.setState({sickNoteSwitch: true}) :
                                                                    this.setState({sickNoteSwitch: false})
                                                            }}
                                                        />
                                                    </Flex>
                                                </FormControl>
                                            }
                                            {this.state.sickNoteSwitch === true &&
                                                <FormControl mt={4}>
                                                    <div className="label-input">
                                                        <input
                                                            // id="file-upload"
                                                            name="fileUpload"
                                                            className="input-profile file-input"
                                                            type="file"
                                                            accept=".jpeg, .png, .pdf, .jpg"
                                                            disabled={this.props.keyss === "onlyView"}
                                                        />
                                                    </div>
                                                </FormControl>}
                                            {this.props.dayOffStatus === "REJECTED" &&
                                                <FormControl
                                                    mt={3}
                                                >
                                                    <FormLabel className="input-label">
                                                        Justificativa da Rejeição
                                                    </FormLabel>
                                                    <Textarea
                                                        name="declineReason"
                                                        type="text"
                                                        defaultValue={this.props.declineReason}
                                                        disabled
                                                    />
                                                </FormControl>
                                            }

                                        </form>
                                    </Flex>
                                </ModalBody>
                                <ModalFooter>
                                    {((this.props.keyss === "USER" && this.props.dayOffStatus !== "APPROVED") ||
                                        this.props.keyss === "ADMIN") && (
                                        <Button
                                            className="modal-cancel-button"
                                            onClick={this.props.handleShowDelete}
                                        >
                                            Excluir
                                        </Button>)}
                                    {(this.props.keyss === "ADMIN" && this.props.dayOffStatus !== "REJECTED") &&
                                        <Button
                                            className="modal-submit-button"
                                            form="approveDayOff"
                                            onClick={this.props.handleShowDecline}
                                        >
                                            Rejeitar
                                        </Button>}
                                    {(this.props.keyss === "ADMIN" && this.props.dayOffStatus !== "APPROVED") &&
                                        <Button
                                            className="modal-submit-button"
                                            colorScheme="green"
                                            form="approveDayOff"
                                            onClick={this.handleApproveDayOff}
                                            isLoading={this.state.isLoadingApprove}
                                        >
                                            Aprovar
                                        </Button>}
                                    {(this.props.keyss === "ADMIN" && this.props.dayOffStatus !== "JUSTIFIED" && this.props.emergency) &&
                                        <Button
                                            className="modal-submit-button"
                                            colorScheme="green"
                                            form="approveDayOff"
                                            onClick={this.handleJustifyDayOff}
                                            isLoading={this.state.isLoadingJustify}
                                        >
                                            Justificar
                                        </Button>}
                                    {this.props.keyss === "onlyView" ||
                                    (this.props.dayOffStatus === "APPROVED" &&
                                        this.props.keyss !== "ADMIN") ? null :
                                        <Button
                                            className="modal-submit-button"
                                            type="submit"
                                            form="CreateDayOff"
                                            isLoading={this.state.isLoading}
                                        >
                                            Salvar
                                        </Button>
                                    }
                                </ModalFooter>
                            </>
                        )
                        }
                    </ModalContent>
                </Modal>
            </div>
        )
    }
}
