import { ArrowBackIcon } from "@chakra-ui/icons";
import { Button, Flex, Heading, HStack, Text, VStack, Icon, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePasswordUsingToken } from "../services/LoginService";
import {AiOutlineCheck} from "react-icons/ai"

export function CreateInitialPassword() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
      } = useForm({ criteriaMode: "all" });
    
      const [isLoading, setIsLoading] = useState(false);
      const password = useRef({});
      password.current = watch("password", "");
      const navigate = useNavigate();
      function getTokenOnUrl() {
        const regexExp =
          /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
        let token = window.location.href.substring();
        const uuid = token.substring(token.lastIndexOf("=") + 1, token.length);
    
        if (!regexExp.test(uuid)) {
          navigate("../");
          return;
        }
    
        return uuid;
      }
    
      const submitPassword = (password) => {
        setIsLoading(true);
        changePasswordUsingToken(getTokenOnUrl(), password)
          .then(() => {
            toast.success(
              "Senha criada com sucesso! Você será redirecionado para a página inicial em 5 segundos."
            );
            setTimeout(() => navigate("../"), 5000);
          })
          .catch(() => {
            toast.error(
              "Token inválido, solicite ao administrador um novo email!"
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
    
      useEffect(() => {
        getTokenOnUrl();
      });
    
      return (
        <>
          <Flex className="container-fullscreen">
            <Flex
              flexDir="column"
              width="617px"
              height="500px"
              backgroundColor="#FFFFFF"
              justifyContent="center"
              alignItems="center"
              padding="30px"
            >
              <VStack align="left" color="#00082699">
                <Button
                  leftIcon={<ArrowBackIcon />}
                  width="fit-content"
                  variant="link"
                  color="#041E50"
                  mb={2}
                  onClick={() => navigate("/")}
                >
                  Voltar
                </Button>
                <Heading
                  fontSize="24px"
                  color="black !important"
                  mb="5px !important"
                >
                  Criar senha inicial
                </Heading>
                <Text>
                  Cadastre uma senha para acessar sua conta.
                </Text>
                <Text>Para uma senha segura:</Text>
    
                <HStack>
                  <Icon as={AiOutlineCheck} />
                  <Text>Mais de 6 caracteres;</Text>
                </HStack>
    
                <HStack>
                  <Icon as={AiOutlineCheck} />
                  <Text>Não utilize nome, sobrenome ou CPF;</Text>
                </HStack>
    
                <HStack>
                  <Icon as={AiOutlineCheck} />
                  <Text>
                    Não utilize letras ou números sequenciais ou repetidos;
                  </Text>
                </HStack>
                <Text mt="8px !important" mb="8px !important">
                  Para prosseguir, defina uma senha.
                </Text>
              </VStack>
    
              <form id="resetPassword" onSubmit={handleSubmit(submitPassword)}>
                <FormControl>
                  <FormLabel className="input-label">Senha</FormLabel>
                  <Input
                    className="input"
                    type="password"
                    {...register("password", {
                      required: "Campo obrigatório",
                      minLength: {
                        value: 6,
                        message: "A senha deve conter no mínimo 6 caracteres",
                      },
                    })}
                  />
                </FormControl>
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <Text className="error-message" key={type}>
                        {message}
                      </Text>
                    ))
                  }
                />
    
                <FormControl mt={1}>
                  <FormLabel className="input-label">Confirmar senha</FormLabel>
                  <Input
                    className="input"
                    type="password"
                    {...register("passwordConfirm", {
                      required: "Campo obrigatório",
                      validate: (value) => {
                        if (watch("password") !== value) {
                          return "As senhas não coincidem!";
                        }
                      },
                    })}
                  />
                </FormControl>
                <ErrorMessage
                  errors={errors}
                  name="passwordConfirm"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <Text className="error-message" key={type}>
                        {message}
                      </Text>
                    ))
                  }
                />
              </form>
              <Flex mt={5}>
                <Button
                  className="button"
                  isLoading={isLoading}
                  width="full"
                  type="submit"
                  form="resetPassword"
                >
                  Criar senha
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </>
      );
}