import {
    Button, Flex,
    FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

export default class ModalWeekend extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.showWeekend}
                    onClose={this.props.clearStates}
                >
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>Atenção!</ModalHeader>
                        <ModalBody>
                            <Flex flexDir="column">
                                <FormLabel mt={3} className="input-label">Não é possível cadastrar dayoff no final de
                                    semana.</FormLabel>
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="modal-submit-button"
                                mr={3}
                                onClick={this.props.clearStates}
                            >
                                Fechar
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </div>
        )
    }
}
