import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
  Textarea
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import AuxiliaryService from "../services/AuxiliaryService";
import EmployeesService from "../services/EmployeesService";

export function ModalEmployees(props) {
    const companyName = process.env.REACT_APP_COMPANY_NAME;
    const inputMargin = "0px";
    const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [role, setRole] = useState(null);
  const [age, setAge] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
      defaultValues:  {
          hasChildren: props.edit ? props.employer.hasChildren?.toString() : null,
          enabled: props.edit ? props.employer.enabled?.toString() : null
      },
      criteriaMode: "all"
  });

  const depHasChildren = watch("hasChildren");
  const depEnabled =  watch("enabled");


  function verifyCep(zip) {
    if (zip.length < 9) return;
    AuxiliaryService.checkCep(zip).then((res) => {
      setValue("country", "Brasil");
      setValue("state", res.data.uf);
      setValue("city", res.data.localidade);
      setValue("district", res.data.bairro);
      setValue("street", res.data.logradouro);
    });
  }

    useEffect(() => {
        const idUser = localStorage.getItem("idUser");
        EmployeesService.getEmployeeById(idUser).then((res) => {
            setRole(res.data.roles[0].role);
        });
    }, []);

  const adminEditar = (employee) => {
        if(depHasChildren === "false") {
            employee.childrenQty = null
        }
        setIsButtonLoading(true);
        EmployeesService.adminUpdateEmployee(props.employer.idUser, employee)
            .then((res) => {
                toast.success(res.data);
                onClose();
                props.fetchAllEmployees();
            })
            .catch((err) => {
                const errors = err.response.data.map((error) => <div>- {error}</div>);
                toast.error(<>{errors}</>);
            })
            .finally(() => {
                setIsButtonLoading(false);
            });
  };

  const create = (employee) => {
      if(depHasChildren === "false") {
          employee.childrenQty = null
      }
      setIsButtonLoading(true);
    EmployeesService.createEmployee(employee)
      .then(res => {
        toast.success("Colaborador criado com sucesso");
        onClose();
        props.fetchAllEmployees();
      })
      .catch((err) => {
        const errors = err.response.data.map((error) => <div>- {error}</div>);
        toast.error(<>{errors}</>);
      })
        .finally(() => {
            setIsButtonLoading(false);
        });
  };

  const calculeAge = (idade) => {
      setAge(AuxiliaryService.checkAge(idade))
  }


  return (
    <>
      <div className="btn-center">
        <Button
          className={props.buttonClassName}
          onClick={() => {
            onOpen();
            reset();
            calculeAge(props.employer?.birthDate)
          }}
        >
          {props.buttonText}
        </Button>
      </div>

      <Modal isOpen={isConfirmDeleteOpen} onClose={onCloseConfirmDelete}>
        <ModalOverlay />
        <ModalHeader>Confirmação</ModalHeader>

        <ModalContent>
          <ModalBody>
            <Text p={3}>
              Tem certeza que deseja cancelar a operação? Todos os dados serão
              perdidos!
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button className="modal-cancel-button" onClick={onCloseConfirmDelete}>
              Não
            </Button>
            <Button
              className="modal-submit-button"
              ml={2}
              onClick={() => {
                onCloseConfirmDelete();
                onClose();
              }}
            >
              Sim
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="full"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center">
              {props.modalTitle}
              <Spacer />

              <CloseIcon
                w="12px"
                h="12px"
                onClick={onOpenConfirmDelete}
                cursor="pointer"
              />
            </Flex>
          </ModalHeader>

          <ModalBody>
            <form
              id="myForm"
              onSubmit={
                  props.edit ? handleSubmit(adminEditar) : handleSubmit(create)
              }
            >
              <SimpleGrid
                columns={["1", "1", "1", "3"]}
                spacingY="20px"
                spacingX="20px"
                width={[null, null, null, "900px"]}
                margin="auto"
              >
                <FormControl>
                  <FormLabel className="input-label">Nome:</FormLabel>
                  <Input
                    className="input-profile"
                    type="text"
                    id="fullName"
                    onBlurCapture={(e) => (e.target.value = e.target.value.trim())}
                    defaultValue={props.edit ? props.employer.fullName : null}
                    {...register("fullName", {
                      required: "Campo obrigatório",
                      pattern: {
                        value:
                          /^(([a-zA-Z\u00C0-\u00FF]{2,})+( [a-zA-Z\u00C0-\u00FF]+)+)$/gm,
                        message:
                          "Insira nome e sobrenome, somente letras e acentos.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="fullName"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <>
                          <Text
                            fontSize="14px"
                            color="rgb(211, 66, 66)"
                            key={type}
                          >
                            {message}
                          </Text>
                        </>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">CPF:</FormLabel>
                  {props.edit && role === "ROLE_ADMIN" ?  (
                    <>
                      <Input

                        as={InputMask}
                        className="input-profile"
                        mask={"999.999.999-99"}
                        type="text"
                        id="cpf"
                        defaultValue={props.employer.cpf}
                        {...register("cpf", {
                            required: "Campo obrigatório",
                            pattern: {
                                value: /^\d{3}.?\d{3}.?\d{3}-?\d{2}$/,
                                message: "Número de CPF inválido",
                            },
                        })}
                      />
                    </>
                  ) : (
                    <>
                      <Input

                        as={InputMask}
                        className="input-profile"
                        mask={"999.999.999-99"}
                        type="text"
                        id="cpf"
                        maskChar={null}
                        {...register("cpf", {
                          required: "Campo obrigatório",
                          pattern: {
                            value: /^\d{3}.?\d{3}.?\d{3}-?\d{2}$/,
                            message: "Número de CPF inválido",
                          },
                        })}
                      />
                    </>
                  )}
                    <ErrorMessage
                        errors={errors}
                        name="cpf"
                        render={({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                                <Text
                                    fontSize="14px"
                                    color="rgb(211, 66, 66)"
                                    key={type}
                                >
                                    {message}
                                </Text>
                            ))
                        }
                    />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Número de Celular</FormLabel>
                  <Input
                    className="input-profile"

                    as={InputMask}
                    mask={"(99)99999-9999"}
                    maskChar={null}
                    type="text"
                    id="phone"
                    defaultValue={props.edit ? props.employer.phone : null}
                    {...register("phone", {
                        required: "Campo obrigatório",
                      pattern: {
                        value: /(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/g,
                        message: "Número de celular inválido",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="phone"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Data de Nascimento:</FormLabel>
                    {props.edit && role === "ROLE_ADMIN" ?  (
                        <>
                            <Input
                                className="input-profile"

                                type="date"
                                max={new Date().toISOString().split("T")[0]}
                                id="birthDate"
                                defaultValue={props.edit ? props.employer.birthDate : null}
                                {...register("birthDate", {
                                })}
                                onBlur={(e) => {
                                    console.log("OnBlur")
                                    calculeAge(e.target.value)
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Input
                                className="input-profile"

                                type="date"
                                max={new Date().toISOString().split("T")[0]}
                                id="birthDate"
                                defaultValue={props.edit ? props.employer.birthDate : null}
                                {...register("birthDate", {
                                })}
                                onBlur={(e) => {
                                    calculeAge(e.target.value)
                                }}
                            />
                        </>
                    )}
                  <ErrorMessage
                    errors={errors}
                    name="birthDate"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Idade</FormLabel>
                  <Input
                    className="input-profile"
                    type="number"
                    id="age"
                    readOnly={true}
                    // defaultValue={age}
                    value={age}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">CEP:</FormLabel>
                  <Input
                    className="input-profile"

                    as={InputMask}
                    mask={"99999-999"}
                    maskChar={null}
                    type="text"
                    id="zip"
                    minLength={9}
                    defaultValue={props.edit ? props.employer.zip : null}
                    {...register("zip", {
                      pattern: {
                        value: /[0-9]{5}-[\d]{3}/g,
                        message: "CEP Inválido",
                      },
                    })}
                    onBlur={(e) => verifyCep(e.target.value)}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="zip"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">País:</FormLabel>
                  <Input
                    className="input-profile"

                    type="text"
                    id="country"
                    minLength={3}
                    title={"Deve conter apenas letras"}
                    pattern={"^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ' ]+$"}
                    defaultValue={props.edit ? props.employer.country : null}
                    {...register("country", {
                        minLength: {
                            value: 2,
                            message: "Deve conter mais que 2 caracteres",
                        },
                        maxLength: {
                            value: 20,
                            message: "Limite máximo de 20 caracteres",
                        },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="country"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Estado:</FormLabel>
                  <Input
                    className="input-profile"
                    type="text"
                    id="state"
                    minLength={2}
                    title={"Deve conter apenas letras"}
                    pattern={"^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ' ]+$"}
                    defaultValue={props.edit ? props.employer.state : null}
                    {...register("state", {
                        minLength: {
                            value: 2,
                            message: "Deve conter mais que 2 caracteres",
                        },
                        maxLength: {
                            value: 20,
                            message: "Limite máximo de 20 caracteres",
                        },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="state"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Cidade:</FormLabel>
                  <Input
                    className="input-profile"
                    type="text"
                    id="city"
                    minLength={2}
                    defaultValue={props.edit ? props.employer.city : null}
                    {...register("city", {
                        minLength: {
                            value: 2,
                            message: "Deve conter mais que 2 caracteres",
                        },
                        maxLength: {
                            value: 50,
                            message: "Limite máximo de 50 caracteres",
                        },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="city"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Bairro:</FormLabel>
                  <Input
                    className="input-profile"
                    type="text"
                    id="district"
                    minLength={2}
                    defaultValue={props.edit ? props.employer.district : null}
                    {...register("district", {
                        minLength: {
                            value: 2,
                            message: "Deve conter mais que 2 caracteres",
                        },
                        maxLength: {
                            value: 250,
                            message: "Limite máximo de 250 caracteres",
                        },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="district"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Rua:</FormLabel>
                  <Input
                    className="input-profile"
                    type="text"
                    id="street"
                    minLength={2}
                    defaultValue={props.edit ? props.employer.street : null}
                    {...register("street", {
                        minLength: {
                            value: 2,
                            message: "Deve conter mais que 2 caracteres",
                        },
                        maxLength: {
                            value: 250,
                            message: "Limite máximo de 250 caracteres",
                        },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="street"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Número:</FormLabel>
                  <Input
                    className="input-profile"
                    type="text"
                    id="number"
                    defaultValue={props.edit ? props.employer.number : null}
                    {...register("number", {
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="number"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                  <FormControl>
                      <FormLabel className="input-label">Complemento:</FormLabel>
                      <Input
                          className="input-profile"
                          type="text"
                          id="complement"
                          defaultValue={props.edit ? props.employer.complement : null}
                          {...register("complement")}
                      />
                  </FormControl>


                <FormControl>
                  <FormLabel className="input-label">Aeroporto mais próximo:</FormLabel>
                  <Input
                    className="input-profile"

                    type="text"
                    id="nearbyAirport"
                    title={"Deve conter apenas letras"}
                    defaultValue={
                      props.edit ? props.employer.nearbyAirport : null
                    }
                    {...register("nearbyAirport", {
                      minLength: {
                        value: 3,
                        message: "Deve conter mais que 3 caracteres",
                      },
                      maxLength: {
                        value: 240,
                        message: "Limite máximo de 240 caracteres",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="nearbyAirport"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">
                      {companyName === "simbiose" ? "Data de entrada na Simbiose:" : "Data de entrada no Grupo:"}
                  </FormLabel>
                  {props.edit && role === "ROLE_ADMIN" ? (
                    <>
                      <Input
                        className="input-profile"

                        type="date"
                        id="startOnTeam"
                        min={"1920-01-01"}
                        defaultValue={props.employer.startOnTeam}
                        {...register("startOnTeam", {
                            required: "Campo obrigatório",
                        })}
                      />
                    </>
                  ) : (
                    <>
                      <Input
                        className="input-profile"

                        type="date"
                        id="startOnTeam"
                        min={"1920-01-01"}
                        {...register("startOnTeam", {
                          required: "Campo obrigatório",
                        })}
                      />
                    </>
                  )}
                    <ErrorMessage
                        errors={errors}
                        name="startOnTeam"
                        render={({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                                <Text
                                    fontSize="14px"
                                    color="rgb(211, 66, 66)"
                                    key={type}
                                >
                                    {message}
                                </Text>
                            ))
                        }
                    />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Estado civil:</FormLabel>
                  <Select
                    className="input-profile"

                    id="maritalState"
                    defaultValue={
                      props.edit ? props.employer.maritalState : null
                    }
                    {...register("maritalState", {
                    })}
                  >
                    <option value="" selected disabled>
                      {" "}
                      Selecione{" "}
                    </option>
                    <option value="Solteiro(a)">Solteiro(a)</option>
                    <option value="Casado(a)">Casado(a)</option>
                    <option value="União Estável">União Estável</option>
                    <option value="Viúvo(a)">Viúvo(a)</option>
                    <option value="Divorciado(a)">Divorciado(a)</option>
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name="maritalState"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Possui filhos?</FormLabel>
                  <Select
                    className="input-profile"
                    id="hasChildren"
                    {...register("hasChildren", {
                    })}
                  >
                    <option value="" selected disabled>
                      {" "}
                      Selecione{" "}
                    </option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name="hasChildren"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                  {depHasChildren === "true" && (
                      <>
                          <FormControl>
                              <FormLabel className="input-label">Quantidade:</FormLabel>
                              <Input
                                  className="input-profile"
                                  type="number"
                                  id="childrenQty"
                                  defaultValue={props.edit ? props.employer.childrenQty : 0}
                                  {...register("childrenQty")}
                              />
                          </FormControl>
                      </>
                  )}

                <FormControl>
                  <FormLabel className="input-label">Chave Pix:</FormLabel>
                  <Input
                    className="input-profile"

                    type="text"
                    id="pixKey"
                    defaultValue={props.edit ? props.employer.pixKey : null}
                    {...register("pixKey", {
                      required: "Campo obrigatório",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="pixKey"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <Text
                          fontSize="14px"
                          color="rgb(211, 66, 66)"
                          key={type}
                        >
                          {message}
                        </Text>
                      ))
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Email:</FormLabel>
                  {props.edit && role === "ROLE_ADMIN" ? (
                    <>
                      <Input
                        className="input-profile"

                        type="email"
                        id="email"
                        defaultValue={props.employer.email}
                        {...register("email", {
                            required: "Campo obrigatório",
                        })}
                      />
                    </>
                  ) : (
                    <>
                      <Input
                        className="input-profile"

                        type="email"
                        id="email"
                        {...register("email", {
                          required: "Campo obrigatório",
                        })}
                      />
                    </>
                  )}
                    <ErrorMessage
                        errors={errors}
                        name="pixKey"
                        render={({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                                <Text
                                    fontSize="14px"
                                    color="rgb(211, 66, 66)"
                                    key={type}
                                >
                                    {message}
                                </Text>
                            ))
                        }
                    />
                </FormControl>

                <FormControl>
                      <FormLabel className="input-label">Dayoffs anteriores:</FormLabel>
                      <Input
                          className="input-profile"

                          type="number"
                          id="daysOffWithdrawn"
                          defaultValue={props.edit ? props.employer.daysOffWithdrawn : null}
                          {...register("daysOffWithdrawn")}
                      />
                      <ErrorMessage
                          errors={errors}
                          name="daysOffWithdrawn"
                          render={({ messages }) =>
                              messages &&
                              Object.entries(messages).map(([type, message]) => (
                                  <>
                                      <Text
                                          fontSize="14px"
                                          color="rgb(211, 66, 66)"
                                          key={type}
                                      >
                                          {message}
                                      </Text>
                                  </>
                              ))
                          }
                      />
                  </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Gênero:</FormLabel>
                  <Select
                      className="input-profile"

                      id="gender"
                      defaultValue={
                          props.edit ? props.employer.gender : null
                      }
                      {...register("gender", {
                      })}
                  >
                      <option value="" selected disabled>
                          {" "}
                          Selecione{" "}
                      </option>
                      <option value="Homem-cisgênero">Homem-cisgênero</option>
                      <option value="Mulher-cisgênro">Mulher-cisgênero</option>
                      <option value="Não-binário">Não-binário</option>
                      <option value="Mulher-trans<">Mulher-trans</option>
                      <option value="Homem-trans">Homem-trans</option>
                      <option value="Outro">Outro</option>
                  </Select>
                  <ErrorMessage
                      errors={errors}
                      name="gender"
                      render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                              <Text
                                  fontSize="14px"
                                  color="rgb(211, 66, 66)"
                                  key={type}
                              >
                                  {message}
                              </Text>
                          ))
                      }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel className="input-label">Salário:</FormLabel>
                  <Input
                      className="input-profile"
                      id="salary"
                      type={"number"}
                      step="0.01"
                      defaultValue={
                          props.edit ? props.employer.salary : null
                      }
                      {...register("salary", {
                          required: "Campo obrigatório",
                          pattern: {
                              value: /^\d+(\.\d{1,2})?$/,
                              message: "O salário deve ser um número com até duas casas decimais",
                          },
                      })}
                  >
                  </Input>
                  <ErrorMessage
                      errors={errors}
                      name="salary"
                      render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                              <Text
                                  fontSize="14px"
                                  color="rgb(211, 66, 66)"
                                  key={type}
                              >
                                  {message}
                              </Text>
                          ))
                      }
                  />
                </FormControl>

              {props.edit ? (
                  <FormControl>
                      <FormLabel className="input-label">Status</FormLabel>
                      <Select
                          className="input-profile"

                          id="enabled"
                          {...register("enabled", {
                              required: "Campo obrigatório",
                          })}
                      >
                          <option value="" selected disabled>
                              {" "}
                              Selecione{" "}
                          </option>
                          <option value="true">Ativo</option>
                          <option value="false">Inativo</option>
                      </Select>
                      <ErrorMessage
                          errors={errors}
                          name="enabled"
                          render={({ messages }) =>
                              messages &&
                              Object.entries(messages).map(([type, message]) => (
                                  <Text
                                      fontSize="14px"
                                      color="rgb(211, 66, 66)"
                                      key={type}
                                  >
                                      {message}
                                  </Text>
                              ))
                          }
                      />
                  </FormControl>
              ) : null}

              {depEnabled === "false" && (
                  <>
                      <FormControl>
                          <FormLabel className="input-label">
                              {companyName === "simbiose" ? "Data de saída na Simbiose:" : "Data de saída no Grupo:"}
                          </FormLabel>
                          {props.edit && role === "ROLE_ADMIN" ? (
                              <>
                                  <Input
                                      className="input-profile"

                                      type="date"
                                      id="firedDate"
                                      min={"1920-01-01"}
                                      defaultValue={props.employer.firedDate}
                                      {...register("firedDate", {
                                      })}
                                  />
                              </>
                          ) : null}
                          <ErrorMessage
                              errors={errors}
                              name="firedDate"
                              render={({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                      <Text
                                          fontSize="14px"
                                          color="rgb(211, 66, 66)"
                                          key={type}
                                      >
                                          {message}
                                      </Text>
                                  ))
                              }
                          />
                      </FormControl>
                  </>
              )}
                  {companyName === "simbiose" && (
                      <FormControl>
                          <FormLabel className="input-label">Data de entrada no Projeto/Alocação: </FormLabel>
                          {props.edit && role === "ROLE_ADMIN" ? (
                              <>
                                  <Input
                                      className="input-profile"
                                      type="date"
                                      id="startOfAllocation"
                                      min={"1920-01-01"}
                                      defaultValue={props.employer.startOfAllocation}
                                      {...register("startOfAllocation")}
                                  />
                              </>
                          ) : (
                              <>
                                  <Input
                                      className="input-profile"
                                      type="date"
                                      id="startOfAllocation"
                                      min={"1920-01-01"}
                                      {...register("startOfAllocation")}
                                  />
                              </>
                          )}
                          <ErrorMessage
                              errors={errors}
                              name="startOfAllocation"
                              render={({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                      <Text
                                          fontSize="14px"
                                          color="rgb(211, 66, 66)"
                                          key={type}
                                      >
                                          {message}
                                      </Text>
                                  ))
                              }
                          />
                      </FormControl>
                  )}

                  <FormControl>
                      <FormLabel className="input-label">Contato de emergência</FormLabel>
                      <Textarea
                          className="input-profile"
                          type="text"
                          id="emergencyContact"
                          defaultValue={props.edit ? props.employer.emergencyContact : null}
                          {...register("emergencyContact", {
                              required: "Campo obrigatório"
                          })}
                      />
                      <ErrorMessage
                          errors={errors}
                          name="emergencyContact"
                          render={({ messages }) =>
                              messages &&
                              Object.entries(messages).map(([type, message]) => (
                                  <Text
                                      fontSize="14px"
                                      color="rgb(211, 66, 66)"
                                      key={type}
                                  >
                                      {message}
                                  </Text>
                              ))
                          }
                      />
                  </FormControl>
                  
              </SimpleGrid>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button className="modal-cancel-button" mr={3} onClick={onOpenConfirmDelete}>
              Cancelar
            </Button>
            <Button
                className="modal-submit-button"
                type="submit"
                form="myForm"
                isLoading={isButtonLoading}>
              {props.buttonName}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
