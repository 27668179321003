import { PAGEABLE_EVENTS_URL, SEND_FILE_FROM_DAYOFF } from "../endpoints/endpoints";
import { api } from "./AxiosService";

export function updateDayOff(dayoff) {
    return api.post(PAGEABLE_EVENTS_URL, dayoff);
}

export function getDayOffByStatus(status) {
    return api.get(PAGEABLE_EVENTS_URL + "?status=" + status + "&page=0&size=25&sortDirection=ASC")
}

export function getDayoffByUserId(idUser) {
    return api.get(PAGEABLE_EVENTS_URL + "?idUser=" + idUser)
}

export function getAtestadoByDayoffId(eventId) {
    return api.get(SEND_FILE_FROM_DAYOFF + "/atestado?eventId=" + eventId)
}
