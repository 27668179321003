import React, {useState} from "react";
import {Button} from "@chakra-ui/react";
import EmployeesService from "../services/EmployeesService";
import {toast} from "react-toastify";

export function ResendEmailButton(props) {

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    function emailResend(email) {
        setIsButtonLoading(true);
        EmployeesService.resendEmail(email).then(res => {
            toast.success(res.data)
        }).catch(error => {
            toast.error(error.response.data)
        }).finally(() => {
            setIsButtonLoading(false);
        })
    }

    return(
        <>
            <Button
                isLoading={isButtonLoading}
                loadingText='Enviando'
                className={props.buttonClassName}
                variant="link"
                onClick={() => emailResend(props.userEmail)}
                >
                {props.buttonText}
            </Button>
        </>
    )
}